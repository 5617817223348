import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const tableStyles = makeStyles({
  customTable: {
    marginTop: 25,
    maxHeight: 680,
    boxShadow: "none",
    "& .MuiTable-root": {
      minWidth: 1300,
      tableLayout: "fixed",
      "& .MuiTableCell-root": {
        fontWeight: 500,
        color: colors.black,
        border: "none",
      },
      "& .MuiTableHead-root": {
        boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
        backgroundColor: colors.white,
        "& .MuiTableCell-root": {
          color: "rgba(33, 33, 33, 0.7)",
          padding: "30px 10px",
          backgroundColor: colors.white,
          borderBottom: "1px solid #f4f4f4",
          "&:first-child": {
            paddingLeft: "30px",
          },
          "&:last-child": {
            paddingRight: "30px",
          },
          "&.invoice-number": {
            width: 150,
            paddingRight: "50px",
          },
          "&.issue-date": {
            width: 150,
          },
          "&.bill-to": {
            width: 300,
          },
          "&.ship-to": {
            width: 300,
          },
          "&.total-pieces": {
            width: 120,
            paddingRight: "60px",
          },
          "&.total-weight": {
            width: 150,
            paddingRight: "60px",
          },
          "&.gross-amount": {
            width: 150,
            paddingRight: "60px",
          },
          "&.due-date": {
            width: 150,
          },
          "&.invoice": {
            width: 150,
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableCell-root": {
          padding: "22px 10px",
          "&:first-child": {
            paddingLeft: "30px",
            color: colors.skyblue,
          },
          "& span": {
            display: "block",
          },
          "&:last-child": {
            paddingRight: "30px",
          },
          "&.chat-column": {
            display: "flex",
            "& .MuiButtonBase-root": {
              display: "flex",
              alignItems: "center",
              borderRadius: "6px",
              padding: "7px",
              textDecoration: "none !important",
              backgroundColor: "rgba(0, 174, 239, 0.06)",
              color: colors.black,
              maxWidth: "120px",
              cursor: "pointer",
              minWidth: "50px",
              margin: "0 5px",
              "& img": {
                marginRight: "0px",
              },
            },
          },
          "&.request-status": {
            "& span": {
              position: "relative",
              paddingLeft: 20,
              display: "block",
              "&:before": {
                content: '""',
                position: "absolute",
                backgroundColor: colors.black,
                height: 10,
                width: 10,
                borderRadius: "50%",
                left: 0,
                top: 7,
              },
            },
            "&.requested": {
              "& span": {
                color: colors.green,
                "&:before": {
                  backgroundColor: colors.green,
                },
              },
            },
            "&.acknowledged": {
              "& span": {
                color: colors.red1,
                "&:before": {
                  backgroundColor: colors.red1,
                },
              },
            },
            "&.driver-assigned": {
              "& span": {
                color: colors.yellow1,
                "&:before": {
                  backgroundColor: colors.yellow1,
                },
              },
            },
            "&.request-completed": {
              "& span": {
                color: colors.green1,
                "&:before": {
                  backgroundColor: colors.green1,
                },
              },
            },
          },
        },
      },
    },
  },
  tableWrapper: {
    boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
    backgroundColor: colors.white,
    borderRadius: "6px",
  },
});

export { tableStyles };
