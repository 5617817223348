import {
  FETCH_CUSTOMER_AWB,
  FETCH_CUSTOMER_AWB_SUCCESS,
  FETCH_CUSTOMER_AWB_FAILURE,
  DELETE_CUSTOMER_AWB,
  DELETE_CUSTOMER_AWB_SUCCESS,
  DELETE_CUSTOMER_AWB_FAILURE,
  IMPORT_CUSTOMER_AWB,
  IMPORT_CUSTOMER_AWB_SUCCESS,
  IMPORT_CUSTOMER_AWB_FAILURE,
  FETCH_CUSTOMER_CONSIGNMENT,
  FETCH_CUSTOMER_CONSIGNMENT_SUCCESS,
  FETCH_CUSTOMER_CONSIGNMENT_FAILURE,
  DELETE_CUSTOMER_CONSIGNMENT,
  DELETE_CUSTOMER_CONSIGNMENT_SUCCESS,
  DELETE_CUSTOMER_CONSIGNMENT_FAILURE,
  IMPORT_CUSTOMER_CONSIGNMENT,
  IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS,
  IMPORT_CUSTOMER_CONSIGNMENT_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingCustomerAwb: false,
  customerAwbData: null,
  deletingCustomerAwb: false,
  importingCustomerAwb: false,
  loadingCustomerConsignment: false,
  customerConsignmentData: null,
  deletingCustomerConsignment: false,
  importingCustomerConsignment: false,
};

const AWBConsignmentReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_AWB:
      return { ...state, loadingCustomerAwb: true };
    case FETCH_CUSTOMER_AWB_SUCCESS:
      return {
        ...state,
        customerAwbData: action.payload,
        loadingCustomerAwb: false,
      };
    case FETCH_CUSTOMER_AWB_FAILURE:
      return {
        ...state,
        customerAwbData: action.payload,
        loadingCustomerAwb: false,
      };

    case DELETE_CUSTOMER_AWB:
      return { ...state, deletingCustomerAwb: true };
    case DELETE_CUSTOMER_AWB_SUCCESS:
      return { ...state, deletingCustomerAwb: false };
    case DELETE_CUSTOMER_AWB_FAILURE:
      return { ...state, deletingCustomerAwb: false };

    case IMPORT_CUSTOMER_AWB:
      return { ...state, importingCustomerAwb: true };
    case IMPORT_CUSTOMER_AWB_SUCCESS:
      return { ...state, importingCustomerAwb: false };
    case IMPORT_CUSTOMER_AWB_FAILURE:
      return { ...state, importingCustomerAwb: false };

    case FETCH_CUSTOMER_CONSIGNMENT:
      return { ...state, loadingCustomerConsignment: true };
    case FETCH_CUSTOMER_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        customerConsignmentData: action.payload,
        loadingCustomerConsignment: false,
      };
    case FETCH_CUSTOMER_CONSIGNMENT_FAILURE:
      return {
        ...state,
        customerConsignmentData: action.payload,
        loadingCustomerConsignment: false,
      };

    case DELETE_CUSTOMER_CONSIGNMENT:
      return { ...state, deletingCustomerConsignment: true };
    case DELETE_CUSTOMER_CONSIGNMENT_SUCCESS:
      return { ...state, deletingCustomerConsignment: false };
    case DELETE_CUSTOMER_CONSIGNMENT_FAILURE:
      return { ...state, deletingCustomerConsignment: false };

    case IMPORT_CUSTOMER_CONSIGNMENT:
      return { ...state, importingCustomerConsignment: true };
    case IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS:
      return { ...state, importingCustomerConsignment: false };
    case IMPORT_CUSTOMER_CONSIGNMENT_FAILURE:
      return { ...state, importingCustomerConsignment: false };

    default:
      return state;
  }
};
export default AWBConsignmentReducer;
