import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";
import closeIcon from "@assets/images/close.svg";

import { AddNewItemStyle } from "./style";

function AddNewItem(props) {
  const classes = AddNewItemStyle();
  const [scroll] = useState("body");

  return (
    <div className={classes.AddNewItemWrapper}>
      <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className={classes.customForm}
          onSubmit={props.formik.handleSubmit}
        >
          <DialogTitle>View Item</DialogTitle>
          <DialogContent>
            <div className="form-row">
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text" htmlFor="additional-info">
                    Additional info
                  </label>
                  <TextField
                    id="additionalInfo"
                    placeholder="Width, length"
                    variant="outlined"
                    type="text"
                    disabled
                    value={
                      props.formik.values.additionalInfo &&
                      props.formik.values.additionalInfo.trimStart()
                    }
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl variant="outlined" className={classes.formControl}>
                  <label className="label-text" htmlFor="quantity">
                    Quantity
                  </label>
                  <TextField
                    name="quantity"
                    placeholder="Quantity"
                    variant="outlined"
                    type="text"
                    disabled
                    value={props.formik.values.quantity}
                  />
                </FormControl>
              </div>
            </div>
            {props?.formik?.values?.photo !== "" && (
              <div className="form-row">
                <div className="form-group">
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <label className="label-text" htmlFor="bill-number">
                      View Uploaded Photo
                    </label>
                    <Button
                      className="primary-btn gray-border-btn"
                      color="inherit"
                      disableElevation
                      underlinenone="true"
                      onClick={() => window.open(props.formik.values.photo)}
                    >
                      View
                    </Button>
                  </FormControl>
                </div>
              </div>
            )}
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
export default AddNewItem;
