import React, { useEffect } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useStore } from "@store/store";
import ProfileSideBar from "@components/profile-sidebar";
import Header from "@components/header";
import Loader from "@components/loader";
import {
  FETCH_CHILD_ACCOUNTS,
  FETCH_CHILD_ACCOUNTS_SUCCESS,
  FETCH_CHILD_ACCOUNTS_FAILURE,
} from "@utils/actionTypes";
import { getApi } from "@services/axios";
import { childAccountStyle } from "./style";

function ChildAccountView() {
  const classes = childAccountStyle();
  const [state, dispatch] = useStore();

  // API calling to get child accounts
  const getChildAccount = () => {
    dispatch({ type: FETCH_CHILD_ACCOUNTS });
    getApi("childAccounts", null, false)
      .then((response) => {
        dispatch({
          type: FETCH_CHILD_ACCOUNTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CHILD_ACCOUNTS_FAILURE,
          payload: error.response.data,
        });
      });
  };
  useEffect(() => {
    getChildAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header />
      <div className={classes.childAccountWrapper}>
        <Loader loading={state.common.loadingChildAccounts} />
        <div className="wrapper">
          <div className="container">
            <div className="profile-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar></ProfileSideBar>
              </div>
              <div className="right-content">
                <div className="white-card">
                  <Typography variant="h1">Child Accounts</Typography>
                  <TableContainer>
                    <Table
                      className="account-data-table"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Username</TableCell>
                          <TableCell>Department</TableCell>
                          <TableCell>Email</TableCell>
                        </TableRow>
                      </TableHead>
                      {state?.common?.childAccountsData?.count === 0 ? (
                        <TableBody>
                          <TableRow>
                            <TableCell>No Data Found</TableCell>
                          </TableRow>
                        </TableBody>
                      ) : (
                        state?.common?.childAccountsData?.rows?.map(
                          (item, index) => {
                            return (
                              <TableBody>
                                <TableRow key={index}>
                                  <TableCell>
                                    {item.name ? item.name : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {item.jobTypes?.length
                                      ? item.jobTypes
                                          ?.map((itm) => {
                                            return `${
                                              itm?.customerJobTypesOfJobTypes
                                                ?.name || "-"
                                            } `;
                                          })
                                          .join(", ")
                                      : "-"}
                                  </TableCell>
                                  <TableCell>
                                    {item.email ? item.email : "-"}
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            );
                          }
                        )
                      )}
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChildAccountView;
