import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField, Typography } from "@material-ui/core";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import {
	FETCH_JOB_AWB,
	FETCH_JOB_AWB_SUCCESS,
	FETCH_JOB_AWB_FAILURE,
} from "@utils/actionTypes";
import { routes } from "@utils/constant";
import SearchIcon from "@assets/images/search.svg";
import { getApi } from "@services/axios";

import { AirWayBillStyle } from "./style";
import ULD from "./ulds/uld";
import Loose from "./looses/loose";

function AirWayBill(props) {
	const classes = AirWayBillStyle();
	const { id } = useParams();
	const history = useHistory();
	const [state, dispatch] = useStore();
	const [search, setSearch] = useState("");

	// API calling to get airway bills
	let getAirWayBills = () => {
		const params = {
			...(!!search ? { search } : {}),
		};
		if (
			["Import", "Export", "Temp Control"].includes(
				props.jobsData?.jobTypes?.name
			)
		) {
			dispatch({ type: FETCH_JOB_AWB });
			getApi(`jobs/${id}/airWaybills`, { params }, true)
				.then((response) => {
					dispatch({
						type: FETCH_JOB_AWB_SUCCESS,
						payload: response.data.data,
					});
				})
				.catch((error) => {
					dispatch({ type: FETCH_JOB_AWB_FAILURE, payload: error });
					if (error?.response?.status === 404) {
						history.push(routes.pageNotFound);
					}
				});
		}
	};

	useEffect(() => {
		getAirWayBills();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDebouncedEffect(() => getAirWayBills(), 1000, [search]);
	const handleSearch = (event) => setSearch(event.target.value);

	return (
		<div className={classes.AirWayBillWrapper}>
			<div className={classes.tabHeadingRow}>
				<div className={classes.searchWrapper}>
					<div className="form-gourp">
						<TextField
							placeholder="Search air waybills"
							variant="outlined"
							type="search"
							InputProps={{
								endAdornment: <img src={SearchIcon} alt="Search" />,
							}}
							value={search}
							onChange={handleSearch}
						/>
					</div>
				</div>
			</div>
			{props.jobsData?.cargoTypes?.name === "ULD" ? (
				<div className={classes.customCardWrapper}>
					{state.job.airWayBillData?.count === 0 ? (
						<div className="custom-card">
							<div className="card-header">
								<span className="heading-title">No Data found</span>
							</div>
						</div>
					) : (
						state.job.airWayBillData?.rows?.map((item, index) => {
							return (
								<div className="custom-card" key={index}>
									<div className="card-header">
										<span className="heading-title">{item.number}</span>
										<div className="bill-items-wrapper">
											<ULD
												getIndex={item.id}
												jobsData={props.jobsData}
												getAirWayBills={getAirWayBills}
											/>
										</div>
									</div>
									<div className="card-body">
										<ul>
											<li>
												<Typography variant="h6">Flight:</Typography>
												<div className="value-block">
													<p>{item?.flight || "-"}</p>
												</div>
											</li>
											<li>
												<Typography variant="h6">
													Remainder Quantity:
												</Typography>
												<div className="value-block">
													<p>{item?.remainderQty || "-"}</p>
												</div>
											</li>
											<li>
												<Typography variant="h6">Reason Remainder:</Typography>
												<div className="value-block">
													<p>
														{item?.remainderQty > 0 && item?.remainderReason
															? item?.remainderReason
															: "-"}
													</p>
												</div>
											</li>
											<li>
												<Typography variant="h6">Weight Loaded:</Typography>
												<div className="value-block">
													<p>
														{item.weightLoaded ? `${item.weightLoaded} Kg` : 0}
													</p>
												</div>
											</li>
											<li>
												<Typography variant="h6">Total Weight:</Typography>
												<div className="value-block">
													<p>{item.weight ? `${item.weight} Kg` : "-"} </p>
												</div>
											</li>
											<li>
												<Typography variant="h6">Quantity Loaded:</Typography>
												<div className="value-block">
													<p>{item.quantityLoaded || 0}</p>
												</div>
											</li>
											<li>
												<Typography variant="h6">Total Quantity:</Typography>
												<div className="value-block">
													<p>{item.quantity || "-"}</p>
												</div>
												<Typography
													className={`${
														item.remainderQty > 0 ? "h1-red" : "h1-nrml"
													}`}
													variant="h6 new"
												>
													Loaded:
												</Typography>

												<div
													className={`${
														item.remainderQty > 0
															? "value-block-red"
															: "value-block1"
													}`}
												>
													<p>
														{item.quantityLoaded
															? `${item.quantityLoaded}`
															: "0"}
													</p>
												</div>
											</li>
										</ul>
									</div>
								</div>
							);
						})
					)}
				</div>
			) : (
				<div className={classes.customCardWrapper}>
					{state.job.airWayBillData?.count === 0 ? (
						<div className="custom-card">
							<div className="card-header">
								<span className="heading-title">No Data found</span>
							</div>
						</div>
					) : (
						state?.job?.airWayBillData?.rows?.map((item, index) => (
							<div className="custom-card" key={index}>
								<div className="card-header">
									<span className="heading-title">{item.number}</span>
									<div className={classes.moreLinkWrapper}>
										<div className="bill-items-wrapper">
											<Loose
												getIndex={item.id}
												jobsData={props.jobsData}
												getAirWayBills={getAirWayBills}
											/>
										</div>
									</div>
								</div>
								<div className="card-body">
									<ul>
										<li>
											<Typography variant="h6">Flight:</Typography>
											<div className="value-block">
												<p>{item?.flight || "-"}</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Remainder Quantity:</Typography>
											<div className="value-block">
												<p>{item?.remainderQty || "-"}</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Reason Remainder:</Typography>
											<div className="value-block">
												<p>
													{item?.remainderQty > 0 && item?.remainderReason
														? item?.remainderReason
														: "-"}
												</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Weight Loaded:</Typography>
											<div className="value-block">
												<p>
													{item.weightLoaded ? `${item.weightLoaded} Kg` : 0}
												</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Total Weight:</Typography>
											<div className="value-block">
												<p>{item.weight ? `${item.weight} Kg` : "-"} </p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Quantity Loaded:</Typography>
											<div className="value-block">
												<p>{item.quantityLoaded || 0}</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Total Quantity:</Typography>
											<div className="value-block">
												<p>{item.quantity || "-"}</p>
											</div>
											<Typography
												className={`${
													item.remainderQty > 0 ? "h1-red" : "h1-nrml"
												}`}
												variant="h6 new"
											>
												Loaded:
											</Typography>

											<div
												className={`${
													item.remainderQty > 0
														? "value-block-red"
														: "value-block1"
												}`}
											>
												<p>
													{item.quantityLoaded ? `${item.quantityLoaded}` : "0"}
												</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
						))
					)}
				</div>
			)}
		</div>
	);
}
export default AirWayBill;
