import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { MenuItem, Menu, Button } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import cx from "classnames";

import User from "@assets/images/user.png";
import profile from "@assets/images/profile.svg";
import profileRed from "@assets/images/profile-red.svg";
import profileWhite from "@assets/images/profile-white.svg";
import childAccount from "@assets/images/child-account.svg";
import childAccountRed from "@assets/images/child-account-red.svg";
import childAccountWhite from "@assets/images/child-account-white.svg";
import notification from "@assets/images/notification.svg";
import notificationRed from "@assets/images/notification-red.svg";
import notificationWhite from "@assets/images/notification-white.svg";
// import consignmentNormal from "@assets/images/consignment-icon-normal.svg";
// import consignmentWhite from "@assets/images/consignment-icon-white.svg";
// import consignmentRed from "@assets/images/consignment-icon-red.svg";
// import awbNormal from "@assets/images/awb-icon-normal.svg";
// import awbRed from "@assets/images/awb-icon-red.svg";
// import awbWhite from "@assets/images/awb-icon-white.svg";
// import organisation from "@assets/images/organisation.svg";
// import organisationRed from "@assets/images/organisation-red.svg";
// import organisationWhite from "@assets/images/organisation-white.svg";
import { routes } from "@utils/constant";
import { getUserData } from "@utils/commonFunctions";
import Logout from "../logout";

function MenuPopup(props) {
  const [activeRoute, setActiveRoute] = useState("/");
  const location = useLocation();
  useEffect(() => {
    setActiveRoute(location.pathname);
  }, [location]);

  return (
    <>
      <ul className="profile-menu">
        <li>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={props.handleClick}
            className="light-blue-btn"
            endIcon={<ExpandMore />}
          >
            <img
              src={
                getUserData()?.profileImage ? getUserData()?.profileImage : User
              }
              alt={getUserData()?.name}
            />
            {getUserData()?.name}
          </Button>

          <Menu
            id="simple-menu"
            className={props.classes.customMenu}
            menuprops={{
              classes: { paper: props.classes.customMenu },
              TransitionProps: {
                onEnter: props.handleEnter, // Specify your handleEnter function
              },
            }}
            anchorEl={props.anchorEl}
            keepMounted
            open={Boolean(props.anchorEl)}
            onClose={props.handleClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem
              className={cx({ active: activeRoute === routes.myProfile })}
              onClick={() => props.handleClose(routes.myProfile)}
            >
              <img src={profile} alt="Profile" className="normal-img" />
              <img src={profileRed} alt="Profile" className="hover-img" />
              <img src={profileWhite} alt="Profile" className="active-img" />
              My profile
            </MenuItem>
            <MenuItem
              className={cx({ active: activeRoute === routes.childAccount })}
              onClick={() => props.handleClose(routes.childAccount)}
            >
              <img
                src={childAccount}
                alt="Child Account"
                className="normal-img"
              />
              <img
                src={childAccountRed}
                alt="Child Account"
                className="hover-img"
              />
              <img
                src={childAccountWhite}
                alt="Child Account"
                className="active-img"
              />
              Child Accounts
            </MenuItem>

            <MenuItem
              className={cx({ active: activeRoute === routes.notifications })}
              onClick={() => props.handleClose(routes.notifications)}
            >
              <img
                src={notification}
                alt="Notifications "
                className="normal-img"
              />
              <img
                src={notificationRed}
                alt="Notifications"
                className="hover-img"
              />
              <img
                src={notificationWhite}
                alt="Notifications"
                className="active-img"
              />
              Notifications
            </MenuItem>
            <MenuItem
              className={cx({ active: activeRoute === routes.upload })}
              onClick={() => props.handleClose(routes.upload)}
            >
              <img
                src={childAccount}
                alt="upload"
                className="normal-img"
              />
              <img
                src={childAccountRed}
                alt="upload"
                className="hover-img"
              />
              <img
                src={childAccountWhite}
                alt="upload"
                className="active-img"
              />
              Upload
            </MenuItem>
            <MenuItem
              className={cx({ active: activeRoute === routes.integration })}
              onClick={() => props.handleClose(routes.integration)}
            >
              <img
                src={childAccount}
                alt="integration"
                className="normal-img"
              />
              <img
                src={childAccountRed}
                alt="integration"
                className="hover-img"
              />
              <img
                src={childAccountWhite}
                alt="integration"
                className="active-img"
              />
              Integration
            </MenuItem>
            {/* <MenuItem
              className={cx({ active: activeRoute === routes.awb })}
              onClick={() => props.handleClose(routes.awb)}
            >
              <img src={awbNormal} alt="awb " className="normal-img" />
              <img src={awbRed} alt="awb" className="hover-img" />
              <img src={awbWhite} alt="awb" className="active-img" />
              AWB
            </MenuItem>
            <MenuItem
              className={cx({ active: activeRoute === routes.consignment })}
              onClick={() => props.handleClose(routes.consignment)}
            >
              <img
                src={consignmentNormal}
                alt="consignment "
                className="normal-img"
              />
              <img
                src={consignmentRed}
                alt="consignment"
                className="hover-img"
              />
              <img
                src={consignmentWhite}
                alt="consignment"
                className="active-img"
              />
              Consignment
            </MenuItem> */}
            <MenuItem className="logout-link">
              <Logout />
            </MenuItem>
          </Menu>
        </li>
      </ul>
    </>
  );
}
export default MenuPopup;
