import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { TextField, Typography } from "@material-ui/core";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import SearchIcon from "@assets/images/search.svg";
import {
	FETCH_JOB_CONSIGNMENT,
	FETCH_JOB_CONSIGNMENT_SUCCESS,
	FETCH_JOB_CONSIGNMENT_FAILURE,
} from "@utils/actionTypes";
import { routes } from "@utils/constant";
import { getApi } from "@services/axios";
import ConsignmentsData from "./sidebar";
import { ConsignmentsStyle } from "./style";

function Consignments(props) {
	const classes = ConsignmentsStyle();
	const [search, setSearch] = useState("");
	const [state, dispatch] = useStore();

	const { id } = useParams();
	const history = useHistory();

	// API calling to get list of consignment
	let getConsignment = () => {
		const params = {
			...(!!search ? { search } : {}),
		};
		if (
			["Metro", "Airside", "Transfer", "Ad-Hoc", "Interstate"].includes(
				props.jobsData?.jobTypes?.name
			)
		) {
			dispatch({ type: FETCH_JOB_CONSIGNMENT });
			getApi(`jobs/${id}/consignments`, { params }, true)
				.then((response) => {
					dispatch({
						type: FETCH_JOB_CONSIGNMENT_SUCCESS,
						payload: response.data.data,
					});
				})
				.catch((error) => {
					dispatch({ type: FETCH_JOB_CONSIGNMENT_FAILURE, payload: error });
					if (error?.response?.status === 404) {
						history.push(routes.pageNotFound);
					}
				});
		}
	};

	useEffect(() => {
		getConsignment();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDebouncedEffect(() => getConsignment(), 1000, [search]);
	const handleSearch = (event) => setSearch(event.target.value);

	return (
		<div className={classes.ConsignmentsWrapper}>
			<div className={classes.tabHeadingRow}>
				<div className={classes.searchWrapper}>
					<div className="form-gourp">
						<TextField
							placeholder="Search consignments or items"
							variant="outlined"
							type="search"
							InputProps={{
								endAdornment: <img src={SearchIcon} alt="Search" />,
							}}
							value={search}
							onChange={handleSearch}
						/>
					</div>
				</div>
			</div>
			<div className={classes.customCardWrapper}>
				{state.job.jobConsignmentData?.count === 0 ? (
					<div className="custom-card">
						<div className="card-header">
							<span className="heading-title">No Data found</span>
						</div>
					</div>
				) : (
					state.job.jobConsignmentData?.rows?.map((item, index) => {
						return (
							<div className="custom-card" key={index}>
								<div className="card-header">
									<span className="heading-title">{item.number}</span>
									<div className="bill-items-wrapper">
										<ConsignmentsData
											getIndex={item.id}
											getConsignment={() => getConsignment()}
											jobsData={props.jobsData}
										/>
									</div>
								</div>
								<div className="card-body">
									<ul>
										{item.photo && (
											<li>
												<Typography variant="h6">View Photo:</Typography>
												<div className="value-block">
													<p
														className="photo"
														onClick={() => window.open(item?.photo)}
													>
														View
													</p>
												</div>
											</li>
										)}
										<li>
											<Typography variant="h6">Reason Remainder:</Typography>
											<div className="value-block">
												<p>
													{item?.remainderQty > 0 && item?.remainderReason
														? item?.remainderReason
														: "-"}
												</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Weight Loaded:</Typography>
											<div className="value-block">
												<p>
													{item.weightLoaded ? `${item.weightLoaded} Kg` : "-"}
												</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Total Weight:</Typography>
											<div className="value-block">
												<p>{item.weight ? `${item.weight} Kg` : "-"}</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Quantity Loaded:</Typography>
											<div className="value-block">
												<p>{item.quantityLoaded || "-"}</p>
											</div>
										</li>
										<li>
											<Typography variant="h6">Total Quantity:</Typography>
											<div className="value-block">
												<p>{item.quantity ? `${item.quantity}` : "-"}</p>
											</div>
											<Typography
												className={`${
													item.remainderQty > 0 ? "h1-red" : "h1-nrml"
												}`}
												variant="h6 new"
											>
												Loaded:
											</Typography>

											<div
												className={`${
													item.remainderQty > 0
														? "value-block-red"
														: "value-block1"
												}`}
											>
												<p>
													{item.quantityLoaded ? `${item.quantityLoaded}` : "0"}
												</p>
											</div>
										</li>
									</ul>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}
export default Consignments;
