export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const LOGOUT = "LOGOUT";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";

export const SET_PROFILE = "SET_PROFILE";
export const SET_PROFILE_SUCCESS = "SET_PROFILE_SUCCESS";
export const SET_PROFILE_FAILURE = "SET_PROFILE_FAILURE";

export const PROFILE_VERIFY_EMAIL = "PROFILE_VERIFY_EMAIL";
export const PROFILE_VERIFY_EMAIL_SUCCESS = "PROFILE_VERIFY_EMAIL_SUCCESS";
export const PROFILE_VERIFY_EMAIL_FAILURE = "PROFILE_VERIFY_EMAIL_FAILURE";

export const FETCH_CUSTOMER_AWB = "FETCH_CUSTOMER_AWB";
export const FETCH_CUSTOMER_AWB_SUCCESS = "FETCH_CUSTOMER_AWB_SUCCESS";
export const FETCH_CUSTOMER_AWB_FAILURE = "FETCH_CUSTOMER_AWB_FAILURE";

export const DELETE_CUSTOMER_AWB = "DELETE_CUSTOMER_AWB";
export const DELETE_CUSTOMER_AWB_SUCCESS = "DELETE_CUSTOMER_AWB_SUCCESS";
export const DELETE_CUSTOMER_AWB_FAILURE = "DELETE_CUSTOMER_AWB_FAILURE";

export const IMPORT_CUSTOMER_AWB = "IMPORT_CUSTOMER_AWB";
export const IMPORT_CUSTOMER_AWB_SUCCESS = "IMPORT_CUSTOMER_AWB_SUCCESS";
export const IMPORT_CUSTOMER_AWB_FAILURE = "IMPORT_CUSTOMER_AWB_FAILURE";

export const FETCH_CUSTOMER_CONSIGNMENT = "FETCH_CUSTOMER_CONSIGNMENT";
export const FETCH_CUSTOMER_CONSIGNMENT_SUCCESS =
  "FETCH_CUSTOMER_CONSIGNMENT_SUCCESS";
export const FETCH_CUSTOMER_CONSIGNMENT_FAILURE =
  "FETCH_CUSTOMER_CONSIGNMENT_FAILURE";

export const DELETE_CUSTOMER_CONSIGNMENT = "DELETE_CUSTOMER_CONSIGNMENT";
export const DELETE_CUSTOMER_CONSIGNMENT_SUCCESS =
  "DELETE_CUSTOMER_CONSIGNMENT_SUCCESS";
export const DELETE_CUSTOMER_CONSIGNMENT_FAILURE =
  "DELETE_CUSTOMER_CONSIGNMENT_FAILURE";

export const IMPORT_CUSTOMER_CONSIGNMENT = "IMPORT_CUSTOMER_CONSIGNMENT";
export const IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS =
  "IMPORT_CUSTOMER_CONSIGNMENT_SUCCESS";
export const IMPORT_CUSTOMER_CONSIGNMENT_FAILURE =
  "IMPORT_CUSTOMER_CONSIGNMENT_FAILURE";

export const IMAGE_UPLOAD = "IMAGE_UPLOAD";
export const IMAGE_UPLOAD_SUCCESS = "IMAGE_UPLOAD_SUCCESS";
export const IMAGE_UPLOAD_FAILURE = "IMAGE_UPLOAD_FAILURE";

export const FETCH_CHILD_ACCOUNTS = "FETCH_CHILD_ACCOUNTS";
export const FETCH_CHILD_ACCOUNTS_SUCCESS = "FETCH_CHILD_ACCOUNTS_SUCCESS";
export const FETCH_CHILD_ACCOUNTS_FAILURE = "FETCH_CHILD_ACCOUNTS_FAILURE";

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS";
export const GET_NOTIFICATION_FAILURE = "GET_NOTIFICATION_FAILURE";

export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS";
export const SET_NOTIFICATION_FAILURE = "SET_NOTIFICATION_FAILURE";

export const FETCH_INVOICES = "FETCH_INVOICES";
export const FETCH_INVOICES_SUCCESS = "FETCH_INVOICES_SUCCESS";
export const FETCH_INVOICES_FAILURE = "FETCH_INVOICES_FAILURE";

export const SET_SELECTED_TRUCK_ID = "SET_SELECTED_TRUCK_ID";

export const FETCH_ONLINE_REQUEST = "FETCH_ONLINE_REQUEST";
export const FETCH_ONLINE_REQUEST_SUCCESS = "FETCH_ONLINE_REQUEST_SUCCESS";
export const FETCH_ONLINE_REQUEST_FAILURE = "FETCH_ONLINE_REQUEST_FAILURE";

export const CREATE_ONLINE_REQUEST = "CREATE_ONLINE_REQUEST";
export const CREATE_ONLINE_REQUEST_SUCCESS = "CREATE_ONLINE_REQUEST_SUCCESS";
export const CREATE_ONLINE_REQUEST_FAILURE = "CREATE_ONLINE_REQUEST_FAILURE";

export const EDIT_ONLINE_REQUEST = "EDIT_ONLINE_REQUEST";
export const EDIT_ONLINE_REQUEST_SUCCESS = "EDIT_ONLINE_REQUEST_SUCCESS";
export const EDIT_ONLINE_REQUEST_FAILURE = "EDIT_ONLINE_REQUEST_FAILURE";

export const DELETE_ONLINE_REQUEST = "DELETE_ONLINE_REQUEST";
export const DELETE_ONLINE_REQUEST_SUCCESS = "DELETE_ONLINE_REQUEST_SUCCESS";
export const DELETE_ONLINE_REQUEST_FAILURE = "DELETE_ONLINE_REQUEST_FAILURE";

export const GET_ONLINE_REQUEST_BY_ID = "GET_ONLINE_REQUEST_BY_ID";
export const GET_ONLINE_REQUEST_BY_ID_SUCCESS =
  "GET_ONLINE_REQUEST_BY_ID_SUCCESS";
export const GET_ONLINE_REQUEST_BY_ID_FAILURE =
  "GET_ONLINE_REQUEST_BY_ID_FAILURE";

export const GET_ONLINE_REQUEST_CHAT = "GET_ONLINE_REQUEST_CHAT";
export const GET_ONLINE_REQUEST_CHAT_SUCCESS =
  "GET_ONLINE_REQUEST_CHAT_SUCCESS";
export const GET_ONLINE_REQUEST_CHAT_FAILURE =
  "GET_ONLINE_REQUEST_CHAT_FAILURE";

export const SAVE_ONLINE_REQUEST_CHAT = "SAVE_ONLINE_REQUEST_CHAT";
export const SAVE_ONLINE_REQUEST_CHAT_SUCCESS =
  "SAVE_ONLINE_REQUEST_CHAT_SUCCESS";
export const SAVE_ONLINE_REQUEST_CHAT_FAILURE =
  "SAVE_ONLINE_REQUEST_CHAT_FAILURE";

export const FETCH_ONLINE_REQUEST_CONSIGNMENT =
  "FETCH_ONLINE_REQUEST_CONSIGNMENT";
export const FETCH_ONLINE_REQUEST_CONSIGNMENT_SUCCESS =
  "FETCH_ONLINE_REQUEST_CONSIGNMENT_SUCCESS";
export const FETCH_ONLINE_REQUEST_CONSIGNMENT_FAILURE =
  "FETCH_ONLINE_REQUEST_CONSIGNMENT_FAILURE";

export const ADD_ONLINE_REQUEST_CONSIGNMENT = "ADD_ONLINE_REQUEST_CONSIGNMENT";
export const ADD_ONLINE_REQUEST_CONSIGNMENT_SUCCESS =
  "ADD_ONLINE_REQUEST_CONSIGNMENT_SUCCESS";
export const ADD_ONLINE_REQUEST_CONSIGNMENT_FAILURE =
  "ADD_ONLINE_REQUEST_CONSIGNMENT_FAILURE";

export const EDIT_ONLINE_REQUEST_CONSIGNMENT =
  "EDIT_ONLINE_REQUEST_CONSIGNMENT";
export const EDIT_ONLINE_REQUEST_CONSIGNMENT_SUCCESS =
  "EDIT_ONLINE_REQUEST_CONSIGNMENT_SUCCESS";
export const EDIT_ONLINE_REQUEST_CONSIGNMENT_FAILURE =
  "EDIT_ONLINE_REQUEST_CONSIGNMENT_FAILURE";

export const DELETE_ONLINE_REQUEST_CONSIGNMENT =
  "DELETE_ONLINE_REQUEST_CONSIGNMENT";
export const DELETE_ONLINE_REQUEST_CONSIGNMENT_SUCCESS =
  "DELETE_ONLINE_REQUEST_CONSIGNMENT_SUCCESS";
export const DELETE_ONLINE_REQUEST_CONSIGNMENT_FAILURE =
  "DELETE_ONLINE_REQUEST_CONSIGNMENT_FAILURE";

export const UPLOAD_IMAGE_ADD_CONSIGNMENT = "UPLOAD_IMAGE_ADD_CONSIGNMENT";
export const UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS =
  "UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS";
export const UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE =
  "UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE";

export const FETCH_CONSIGNMENT_ITEM = "FETCH_CONSIGNMENT_ITEM";
export const FETCH_CONSIGNMENT_ITEM_SUCCESS = "FETCH_CONSIGNMENT_ITEM_SUCCESS";
export const FETCH_CONSIGNMENT_ITEM_FAILURE = "FETCH_CONSIGNMENT_ITEM_FAILURE";

export const ADD_CONSIGNMENT_ITEM = "ADD_CONSIGNMENT_ITEM";
export const ADD_CONSIGNMENT_ITEM_SUCCESS = "ADD_CONSIGNMENT_ITEM_SUCCESS";
export const ADD_CONSIGNMENT_ITEM_FAILURE = "ADD_CONSIGNMENT_ITEM_FAILURE";

export const EDIT_CONSIGNMENT_ITEM = "EDIT_CONSIGNMENT_ITEM";
export const EDIT_CONSIGNMENT_ITEM_SUCCESS = "EDIT_CONSIGNMENT_ITEM_SUCCESS";
export const EDIT_CONSIGNMENT_ITEM_FAILURE = "EDIT_CONSIGNMENT_ITEM_FAILURE";

export const DELETE_CONSIGNMENT_ITEM = "DELETE_CONSIGNMENT_ITEM";
export const DELETE_CONSIGNMENT_ITEM_SUCCESS =
  "DELETE_CONSIGNMENT_ITEM_SUCCESS";
export const DELETE_CONSIGNMENT_ITEM_FAILURE =
  "DELETE_CONSIGNMENT_ITEM_FAILURE";

export const IMAGE_UPLOAD_CONSIGNMENT_ITEM = "IMAGE_UPLOAD_CONSIGNMENT_ITEM";
export const IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS =
  "IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS";
export const IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE =
  "IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE";

export const FETCH_AIR_WAY_BILLS = "FETCH_AIR_WAY_BILLS";
export const FETCH_AIR_WAY_BILLS_SUCCESS = "FETCH_AIR_WAY_BILLS_SUCCESS";
export const FETCH_AIR_WAY_BILLS_FAILURE = "FETCH_AIR_WAY_BILLS_FAILURE";

export const ADD_AWB = "ADD_AWB";
export const ADD_AWB_SUCCESS = "ADD_AWB_SUCCESS";
export const ADD_AWB_FAILURE = " ADD_AWB_FAILURE";

export const EDIT_AWB = "EDIT_AWB";
export const EDIT_AWB_SUCCESS = "EDIT_AWB_SUCCESS";
export const EDIT_AWB_FAILURE = "EDIT_AWB_FAILURE";

export const DELETE_AWB = "DELETE_AWB";
export const DELETE_AWB_SUCCESS = "DELETE_AWB_SUCCESS";
export const DELETE_AWB_FAILURE = "DELETE_AWB_FAILURE";

export const FETCH_AWB_LOOSE = "FETCH_AWB_LOOSE";
export const FETCH_AWB_LOOSE_SUCCESS = "FETCH_AWB_LOOSE_SUCCESS";
export const FETCH_AWB_LOOSE_FAILURE = "FETCH_AWB_LOOSE_FAILURE";

export const ADD_AWB_LOOSE = "ADD_AWB_LOOSE";
export const ADD_AWB_LOOSE_SUCCESS = "ADD_AWB_LOOSE_SUCCESS";
export const ADD_AWB_LOOSE_FAILURE = "ADD_AWB_LOOSE_FAILURE";

export const EDIT_AWB_LOOSE = "EDIT_AWB_LOOSE";
export const EDIT_AWB_LOOSE_SUCCESS = "EDIT_AWB_LOOSE_SUCCESS";
export const EDIT_AWB_LOOSE_FAILURE = "EDIT_AWB_LOOSE_FAILURE";

export const DELETE_AWB_LOOSE = "DELETE_AWB_LOOSE";
export const DELETE_AWB_LOOSE_SUCCESS = "DELETE_AWB_LOOSE_SUCCESS";
export const DELETE_AWB_LOOSE_FAILURE = "DELETE_AWB_LOOSE_FAILURE";

export const FETCH_AWB_ULD = "FETCH_AWB_ULD";
export const FETCH_AWB_ULD_SUCCESS = "FETCH_AWB_ULD_SUCCESS";
export const FETCH_AWB_ULD_FAILURE = "FETCH_AWB_ULD_FAILURE";

export const ADD_AWB_ULD = "ADD_AWB_ULD";
export const ADD_AWB_ULD_SUCCESS = "ADD_AWB_ULD_SUCCESS";
export const ADD_AWB_ULD_FAILURE = "ADD_AWB_ULD_FAILURE";

export const EDIT_AWB_ULD = "EDIT_AWB_ULD";
export const EDIT_AWB_ULD_SUCCESS = "EDIT_AWB_ULD_SUCCESS";
export const EDIT_AWB_ULD_FAILURE = "EDIT_AWB_ULD_FAILURE";

export const DELETE_AWB_ULD = "DELETE_AWB_ULD";
export const DELETE_AWB_ULD_SUCCESS = "DELETE_AWB_ULD_SUCCESS";
export const DELETE_AWB_ULD_FAILURE = "DELETE_AWB_ULD_FAILURE";

export const GET_JOBTYPES = "GET_JOBTYPES";
export const GET_JOBTYPES_SUCCESS = "GET_JOBTYPES_SUCCESS";
export const GET_JOBTYPES_FAILURE = "GET_JOBTYPES_FAILURE";

export const FETCH_TRUCKS = "FETCH_TRUCKS";
export const FETCH_TRUCKS_SUCCESS = "FETCH_TRUCKS_SUCCESS";
export const FETCH_TRUCKS_FAILURE = "FETCH_TRUCKS_FAILURE";

export const GET_CARGO_TYPE = "GET_CARGO_TYPE";
export const GET_CARGO_TYPE_SUCCESS = "GET_CARGO_TYPE_SUCCESS";
export const GET_CARGO_TYPE_FAILURE = "GET_CARGO_TYPE_FAILURE";

export const GET_REQUEST_STATUS = "GET_REQUEST_STATUS";
export const GET_REQUEST_STATUS_SUCCESS = "GET_REQUEST_STATUS_SUCCESS";
export const GET_REQUEST_STATUS_FAILURE = "GET_REQUEST_STATUS_FAILURE";

export const GET_JOB_STATUS = "GET_JOB_STATUS";
export const GET_JOB_STATUS_SUCCESS = "GET_JOB_STATUS_SUCCESS";
export const GET_JOB_STATUS_FAILURE = "GET_JOB_STATUS_FAILURE";

export const GET_CTOS = "GET_CTOS";
export const GET_CTOS_SUCCESS = "GET_CTOS_SUCCESS";
export const GET_CTOS_FAILURE = "GET_CTOS_FAILURE";

export const GET_ADDRESS = "GET_ADDRESS";
export const GET_ADDRESS_SUCCESS = "GET_ADDRESS_SUCCESS";
export const GET_ADDRESS_FAILURE = "GET_ADDRESS_FAILURE";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_DASHBOARD = "GET_DASHBOARD";
export const GET_DASHBOARD_SUCCESS = "GET_DASHBOARD_SUCCESS";
export const GET_DASHBOARD_FAILURE = "GET_DASHBOARD_FAILURE";

export const GET_MAPCOCKPIT = "GET_MAPCOCKPIT";
export const GET_MAPCOCKPIT_SUCCESS = "GET_MAPCOCKPIT_SUCCESS";
export const GET_MAPCOCKPIT_FAILURE = "GET_MAPCOCKPIT_FAILURE";

export const FETCH_REPORTS = "FETCH_REPORTS";
export const FETCH_REPORTS_SUCCESS = "FETCH_REPORTS_SUCCESS";
export const FETCH_REPORTS_FAILURE = "FETCH_REPORTS_FAILURE";

export const DOWNLOAD_REPORTS = "DOWNLOAD_REPORTS";
export const DOWNLOAD_REPORTS_SUCCESS = "DOWNLOAD_REPORTS_SUCCESS";
export const DOWNLOAD_REPORTS_FAILURE = "DOWNLOAD_REPORTS_FAILURE";

export const FETCH_JOBS = "FETCH_JOBS";
export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";

export const FETCH_JOBS_ID = "FETCH_JOBS_ID";
export const FETCH_JOBS_ID_SUCCESS = "FETCH_JOBS_ID_SUCCESS";
export const FETCH_JOBS_ID_FAILURE = "FETCH_JOBS_ID_FAILURE";

export const FETCH_JOB_CONSIGNMENT = "FETCH_JOB_CONSIGNMENT";
export const FETCH_JOB_CONSIGNMENT_SUCCESS = "FETCH_JOB_CONSIGNMENT_SUCCESS";
export const FETCH_JOB_CONSIGNMENT_FAILURE = "FETCH_JOB_CONSIGNMENT_FAILURE";

export const FETCH_JOB_CONSIGNMENT_ITEM = "FETCH_JOB_CONSIGNMENT_ITEM";
export const FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS =
  "FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS";
export const FETCH_JOB_CONSIGNMENT_ITEM_FAILURE =
  "FETCH_JOB_CONSIGNMENT_ITEM_FAILURE";

export const FETCH_JOB_AWB = "FETCH_JOB_AWB";
export const FETCH_JOB_AWB_SUCCESS = "FETCH_JOB_AWB_SUCCESS";
export const FETCH_JOB_AWB_FAILURE = "FETCH_JOB_AWB_FAILURE";

export const FETCH_JOB_AWB_LOOSE = "FETCH_JOB_AWB_LOOSE";
export const FETCH_JOB_AWB_LOOSE_SUCCESS = "FETCH_JOB_AWB_LOOSE_SUCCESS";
export const FETCH_JOB_AWB_LOOSE_FAILURE = "FETCH_JOB_AWB_LOOSE_FAILURE";

export const FETCH_JOB_AWB_ULD = "FETCH_JOB_AWB_ULD";
export const FETCH_JOB_AWB_ULD_SUCCESS = "FETCH_JOB_AWB_ULD_SUCCESS";
export const FETCH_JOB_AWB_ULD_FAILURE = "FETCH_JOB_AWB_ULD_FAILURE";

export const GET_JOB_RUNSHEET = "GET_JOB_RUNSHEET";
export const GET_JOB_RUNSHEET_SUCCESS = "GET_JOB_RUNSHEET_SUCCESS";
export const GET_JOB_RUNSHEET_FAILURE = "GET_JOB_RUNSHEET_FAILURE";

export const DOWNLOAD_PDF_JOB_RUNSHEET = "DOWNLOAD_PDF_JOB_RUNSHEET";
export const DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS =
  "DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS";
export const DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE =
  "DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE";

export const DOWNLOAD_CSV_JOB_RUNSHEET = "DOWNLOAD_CSV_JOB_RUNSHEET";
export const DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS =
  "DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS";
export const DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE =
  "DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE";

export const DASHBOARD_FILTER_REDIRECTION = "DASHBOARD_FILTER_REDIRECTION";
export const CARGO_VOLUME = "CARGO_VOLUME";
export const JOBS_STATUS_REDIRECTION = "JOBS_STATUS_REDIRECTION";

export const RESET_REDIRECTION = "RESET_REDIRECTION";

export const ADD_CONSIGNMENT = "ADD_CONSIGNMENT";
export const CREATE_JOB = "CREATE_JOB";

export const RESET_DATA = "RESET_DATA";

export const FETCH_DRIVERS = "FETCH_DRIVERS";
export const FETCH_DRIVERS_SUCCESS = "FETCH_DRIVERS_SUCCESS";
export const FETCH_DRIVERS_FAILURE = "FETCH_DRIVERS_FAILURE";

export const GENERATE_TOKEN = "GENERATE_TOKEN";
export const GENERATE_TOKEN_SUCCESS = "GENERATE_TOKEN_SUCCESS";
export const GENERATE_TOKEN_FAILURE = "GENERATE_TOKEN_FAILURE";

export const FETCH_PERMISSION_INTEGRATION = "FETCH_PERMISSION_INTEGRATION";
export const FETCH_PERMISSION_INTEGRATION_SUCCESS = "FETCH_PERMISSION_INTEGRATION_SUCCESS";
export const FETCH_PERMISSION_INTEGRATION_FAILURE = "FETCH_PERMISSION_INTEGRATION_FAILURE";

export const FETCH_INTEGRATION_API_PERMISSION = "FETCH_INTEGRATION_API_PERMISSION";
export const FETCH_INTEGRATION_API_PERMISSION_SUCCESS = "FETCH_INTEGRATION_API_PERMISSION_SUCCESS";
export const FETCH_INTEGRATION_API_PERMISSION_FAILURE = "FETCH_INTEGRATION_API_PERMISSION_FAILURE";

export const FETCH_CUSTOMER_INTEGRATION_TOKEN = "FETCH_CUSTOMER_INTEGRATION_TOKEN";
export const FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS = "FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS";
export const FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE = "FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE";

export const GET_STREET_BY_STREET = "GET_STREET_BY_STREET";
export const GET_STREET_BY_STREET_SUCCESS = "GET_STREET_BY_STREET_SUCCESS";
export const GET_STREET_BY_STREET_FAILURE = "GET_STREET_BY_STREET_FAILURE";

export const GET_SOURCES = "GET_SOURCES";
export const GET_SOURCES_SUCCESS = "GET_SOURCES_SUCCESS";
export const GET_SOURCES_FAILURE = "GET_SOURCES_FAILURE";
