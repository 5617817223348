import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from "@material-ui/core";

import closeIcon from "@assets/images/close.svg";
import { AddNewItemStyle } from "./style";

function Popup(props) {
  const [scroll] = useState("body");
  const classes = AddNewItemStyle();

  return (
    <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={props.handleClose} />
      </div>
      <form noValidate autoComplete="off" className={classes.customForm}>
        <DialogTitle>View Loose</DialogTitle>
        <DialogContent>
          <div className="form-row">
            <div className="form-group">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text" htmlFor="Quantity">
                  Quantity
                </label>
                <TextField
                  id="quantity"
                  placeholder="Quantity"
                  variant="outlined"
                  type="text"
                  name="quantity"
                  value={props.formik.values.quantity}
                  disabled
                />
              </FormControl>
            </div>
          </div>
        </DialogContent>
      </form>
    </Dialog>
  );
}
export default Popup;
