import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const requestFormStyle = makeStyles((theme) => ({
  OnlineRequestWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
    background: colors.lightbg,
    minHeight: "100vh",
    "& .request-form-section": {
      padding: "30px",
      "@media (max-width: 767px)": {
        padding: "30px 15px",
      },
    },
  },
}));

export { requestFormStyle };
