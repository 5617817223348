import React from "react";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
} from "@material-ui/core";

import { useStore } from "@store/store";
import Pagination from "@components/pagination";
import Loader from "@components/loader";
import {
  dashboardRequestStatusColors,
  utcToLocal,
} from "@utils/commonFunctions";
import { dashboardListingHeader, routes } from "@utils/constant";
import { TableStyle } from "./style";

function TableListing(props) {
  const classes = TableStyle();
  const [state] = useStore();

  return (
    <>
      <div className={classes.TableWrapper}>
        <Loader loading={state.job?.loadingJobs} />
        <TableContainer component={Paper} className={classes.customTable}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {dashboardListingHeader.map((item, index) => {
                  return (
                    <TableCell key={index} className={item.className}>
                      {item.title}
                      {item.sort && (
                        <TableSortLabel
                          direction={
                            props.orderBy === item.sortTitle
                              ? props.order
                              : "desc"
                          }
                          active={true}
                          onClick={(e) => props.handleSorting(e, item)}
                        ></TableSortLabel>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {state.job?.jobsData?.count === 0 ? (
                <TableRow className="no-data">
                  <TableCell colSpan={7}>
                    <span>No Data Found</span>
                  </TableCell>
                </TableRow>
              ) : (
                state.job.jobsData?.rows?.map((row, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className="jobId">
                        <Link
                          to={{
                            pathname: `${routes.jobDetail}/${row.id}`,
                            state: row,
                          }}
                        >
                          {row.id ? row.id : "-"}
                        </Link>
                      </TableCell>

                      <TableCell className="driver">
                        {row?.drivers ? row?.drivers?.name : "-"}
                      </TableCell>

                      <TableCell className="truck-rego">
                        {row.trucks ? row.trucks?.rego : "-"}
                      </TableCell>

                      {/* https://wymap.atlassian.net/browse/MAPTRAK-931 */}
                      <TableCell
                        className={`status ${dashboardRequestStatusColors(
                          row.jobStatuses ? row.jobStatuses.name : ""
                        )}`}
                      >
                        <span>
                          {row.jobStatuses
                            ? row.jobStatuses.name === "Review Completed"
                              ? "Completed"
                              : row.jobStatuses.name === "Change Driver"
                              ? "In Transit"
                              : row.jobStatuses.name
                            : "-"}
                        </span>
                      </TableCell>
                      <TableCell className="cto">
                        {row.ctos ? row.ctos.name : "-"}
                      </TableCell>
                      <TableCell className="jobType">
                        {row.jobTypes ? row.jobTypes.name : "-"}
                      </TableCell>
                      <TableCell className="cargoType">
                        {row.cargoTypes ? row.cargoTypes.name : "-"}
                      </TableCell>
                      <TableCell className="date">
                        {row.createdAt
                          ? utcToLocal(
                              row.createdAt,
                              row.cities?.timezone,
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </TableCell>
                      <TableCell className="completion-date">
                        {row.completedAt
                          ? utcToLocal(
                              row.completedAt,
                              row.cities?.timezone,
                              "DD/MM/YYYY"
                            )
                          : "-"}
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {state.job?.jobsData?.count !== 0 && (
          <div className="pagination-wrapper">
            <Pagination
              count={state.job?.jobsData?.count}
              page={props.page}
              handleChangePage={props.handleChangePage}
              rowsPerPage={props.rowsPerPage}
              handleChangeRowsPerPage={props.handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
    </>
  );
}
export default TableListing;
