import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import Header from "@components/header";
import Loader from "@components/loader";
import ProfileSideBar from "@components/profile-sidebar";
import validationSchema from "@utils/validationSchemas";
import {
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_FAILURE,
  FETCH_PERMISSION_INTEGRATION,
  FETCH_PERMISSION_INTEGRATION_SUCCESS,
  FETCH_PERMISSION_INTEGRATION_FAILURE,
  FETCH_INTEGRATION_API_PERMISSION,
  FETCH_INTEGRATION_API_PERMISSION_SUCCESS,
  FETCH_INTEGRATION_API_PERMISSION_FAILURE,
  FETCH_CUSTOMER_INTEGRATION_TOKEN,
  FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS,
  FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE

} from "@utils/actionTypes";
import { postApi, getApi } from "@services/axios";
import { integrationTokenHeading, rowsPerPageVal } from "@utils/constant";
import Pagination from "@components/pagination";
import { schema } from "@utils/schemas";
import { getUserId } from "@utils/commonFunctions";
import GenerateToken from "./generate-token";
import { GenerateTokenStyle } from "./style";
import TableDynamic from "./table";
import PopupShowToken from "./popup/show-token";

function IntegrationView() {
  const classes = GenerateTokenStyle();
  const [state, dispatch] = useStore();
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [getGenerateToken, setGenerateToken] = useState(schema.generateTokenSchema);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [openShowTokenPopup, setOpenShowTokenPopup] = useState(false);
  const [token, setToken] = useState("");
  const id = parseInt(getUserId());
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: getGenerateToken,
    validationSchema: validationSchema.generateTokenValidationSchema,
    onSubmit: (value) => {
      let data = {
        name: value.tokenName,
        integrationPermissions: {
          job: value.job,
          truck: value.truck
        },
        permissions: state?.profile?.permissionIntegrationList?.map((permission => permission.name)) || []
      };
      dispatch({
        type: GENERATE_TOKEN,
      });
      postApi(`users/integrationToken`, data)
        .then((response) => {
          getCustomerIntegrationToken();
          handleCloseAddPopup();
          dispatch({
            type: GENERATE_TOKEN_SUCCESS,
          });
          toast.success("Generate Token Successfully");
          setToken(response.data.data.token);
          handleShowTokenPopup();
        })
        .catch((error) => {
          handleCloseAddPopup();
          dispatch({
            type: GENERATE_TOKEN_FAILURE,
          });
          toast.error("Generate Token Failed");
        });

    },
  });

  const handleAddPopup = () => {
    setOpenAddPopup(true);
  };

  const handleCloseAddPopup = () => {
    setOpenAddPopup(false);
    setGenerateToken(schema.generateTokenSchema);
    formik.handleReset();
  };

  const handleShowTokenPopup = () => {
    setOpenShowTokenPopup(true);
};

const handleCloseShowTokenPopup = () => {
    setOpenShowTokenPopup(false);
};

const handleCopyToken = (value) => {
    try {
        navigator.clipboard.writeText(value);
        toast.success("Copy Token Successfully");
        setOpenShowTokenPopup(false);
    } catch (error) {
        toast.error("Copy Token Failed");
    }
}

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // API calling to get the list of added documents.
  let getCustomerIntegrationToken = () => {
    dispatch({ type: FETCH_CUSTOMER_INTEGRATION_TOKEN });
    getApi(`users/${id}/integrationTokens`)
      .then((response) => {
        dispatch({
          type: FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE, payload: err });
      });
  };

  useEffect(() => {
    // API calling to get the list of permission integration
    dispatch({ type: FETCH_PERMISSION_INTEGRATION });
    getApi(`permissions/integration`)
      .then((response) => {
        dispatch({
          type: FETCH_PERMISSION_INTEGRATION_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_PERMISSION_INTEGRATION_FAILURE, payload: err });
      });

    // API calling to get the list of Integration Api Permissions
    dispatch({ type: FETCH_INTEGRATION_API_PERMISSION });
    getApi(`permissions/integrationApiPermissions`)
      .then((response) => {
        dispatch({
          type: FETCH_INTEGRATION_API_PERMISSION_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((err) => {
        dispatch({ type: FETCH_INTEGRATION_API_PERMISSION_FAILURE, payload: err });
      });

    getCustomerIntegrationToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Header />
      <div className={classes.generateTokenWrapper}>
        <Loader
          loading={
            state?.profile?.loadingPermissionIntegration ||
            state?.profile?.loadingIntegrationApiPermission ||
            state?.profile?.loadingCustomerIntegrationToken
          }
        />
        <div className="container">
          <div className="wrapper">
            <div className="profile-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar />
              </div>
              <div className="right-content">
                <div className="white-card">
                  <form noValidate autoComplete="off" className="custom-form">
                    <div className="form-outer">
                      <Typography variant="h2">Integration</Typography>
                    </div>
                    <div className={classes.modalWrapper}>
                      <GenerateToken
                        handleClickOpen={() => handleAddPopup()}
                        handleClose={handleCloseAddPopup}
                        open={openAddPopup}
                        formik={formik}
                        data={state?.profile}
                      />
                    </div>
                    <div className={classes.tableWrapper}>
                      <TableDynamic
                        reportsHeading={integrationTokenHeading}
                      />
                      {state?.profile?.customerIntegrationTokenData?.count !== 0 && (
                        <div className="pagination-wrapper">
                          <Pagination
                            count={state?.profile?.customerIntegrationTokenData?.count}
                            page={page}
                            handleChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                          />
                        </div>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PopupShowToken
          open={openShowTokenPopup}
          handleClose={handleCloseShowTokenPopup}
          handleCopy={handleCopyToken}
          token={token}
        />
      </div>
    </>
  );
}

export default IntegrationView;
