import React from "react";
import { Redirect, Route } from "react-router-dom";

import { isAccessible } from "@utils/commonFunctions";

const PrivateRoute = ({ component: Component, path, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAccessible(path) ? <Component {...props} /> : <Redirect to="/login" />
    }
  />
);

export default PrivateRoute;
