import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@material-ui/core";

import { useStore } from "@store/store";
import invoiceIcon from "@assets/images/invoice-icon.svg";
import invoiceCSVIcon from "@assets/images/invoice-csv-icon.svg";
import Pagination from "@components/pagination";
import { invoiceHeading } from "@utils/constant";
import { tableStyles } from "./style";

function TableListing(props) {
  const classes = tableStyles();
  const [state] = useStore();

  // Handle to download pdf link
  const handleDownload = (item) => {
    window.open(item);
  };

  return (
    <div className={classes.tableWrapper}>
      <TableContainer component={Paper} className={classes.customTable}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {invoiceHeading?.map((item, index) => {
                return (
                  <TableCell className={item.className} key={index}>
                    {item.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.invoice?.invoicesData?.count === 0 ? (
              <TableRow className="no-data">
                <TableCell colSpan={7}>No Data Found</TableCell>
              </TableRow>
            ) : (
              state?.invoice?.invoicesData?.rows?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.invoiceNumber || "-"}</TableCell>
                    <TableCell>{item.issueDate || "-"}</TableCell>
                    <TableCell>
                      <span>{item.billTo}</span>
                    </TableCell>
                    <TableCell>{item.totalQty || "-"} </TableCell>
                    <TableCell>{item.totalWeight || "-"}</TableCell>
                    <TableCell>{item.gross || "-"}</TableCell>
                    <TableCell>{item.dueDate || "-"}</TableCell>
                    <TableCell className="chat-column">
                      <Button
                        title="Download as pdf"
                        onClick={() => handleDownload(item.pdfLink)}
                      >
                        <img src={invoiceIcon} alt="Download as pdf" />
                      </Button>
                      <Button
                        title="Download as csv"
                        onClick={() => handleDownload(item.csvLink)}
                      >
                        <img src={invoiceCSVIcon} alt="Download as csv" />
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {state?.invoice?.invoicesData?.count ? (
        <div className="pagination-wrapper">
          <Pagination
            count={state?.invoice?.invoicesData?.count}
            page={props.page}
            handleChangePage={props.handleChangePage}
            rowsPerPage={props.rowsPerPage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableListing;
