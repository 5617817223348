import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  SwipeableDrawer,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { useStore } from "@store/store";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useFormik } from "formik";
import closeIcon from "@assets/images/close.svg";
import { schema } from "@utils/schemas";
import {
  FETCH_JOB_CONSIGNMENT_ITEM,
  FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS,
  FETCH_JOB_CONSIGNMENT_ITEM_FAILURE,
} from "@utils/actionTypes";
import { getApi } from "@services/axios";
import AddNewItem from "../add-newItem";
import { SideBarStyle } from "./style";

function ConsignmentsData(props) {
  const [open, setOpen] = useState(false);
  const [getState, setState] = useState({ right: false });
  const [getIndex, setIndex] = useState("");
  const [initialValue, setInitialValue] = useState(
    schema.addJobConsignMentItemSchema
  );
  const classes = SideBarStyle();
  const [state, dispatch] = useStore();
  const { id } = useParams();

  let getConsignmentItems = () => {
    dispatch({ type: FETCH_JOB_CONSIGNMENT_ITEM });
    getApi(`jobs/${id}/consignments/${props.getIndex}/items`, null, false)
      .then((response) => {
        dispatch({
          type: FETCH_JOB_CONSIGNMENT_ITEM_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_JOB_CONSIGNMENT_ITEM_FAILURE, payload: error });
      });
  };

  useEffect(() => {
    getConsignmentItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...getState, [anchor]: open });
    if (open) {
      getConsignmentItems();
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event, index) => {
    setIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSidebar = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const handleEditOpen = (item) => {
    setAnchorEl(null);
    setOpen(true);
    setInitialValue({
      additionalInfo: item.additionalInfo,
      quantity: item.quantity,
      photo: item.photo || "",
    });
  };

  return (
    <div className={classes.ULDsWrapper}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="primary-btn blue-btn lg"
            variant="contained"
            color="primary"
            disableElevation
            onClick={toggleDrawer(anchor, true)}
          >
            ITEM
          </Button>
          <SwipeableDrawer
            className={classes.drawerWrapper}
            anchor={anchor}
            open={getState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className="drawer-wrapper">
              <div className="filter-title-block form-group">
                <Typography variant="h2">Items</Typography>
                <img
                  src={closeIcon}
                  alt="Close"
                  onClick={toggleDrawer(anchor, false)}
                />
              </div>
              <AddNewItem
                formik={formik}
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
              />
              {state.job.loadingConsignmentItems ? (
                <div className="circular-progress">
                  <CircularProgress color="inherit" size={60} />
                </div>
              ) : (
                <div className="uld-list-wrapper">
                  {state.job?.consignmentItems?.count === 0 ? (
                    <div className="uld-list-item">
                      <span className="uld-id">No Items Found</span>
                    </div>
                  ) : (
                    state.job?.consignmentItems?.rows?.map((item, index) => {
                      return (
                        <div className="uld-list-item" key={index}>
                          <div className="uld-list-item-info">
                            <span className="uld-id">{item.quantity}</span>
                            {item.additionalInfo ? (
                              <span className="uld-additionalInfo">
                                {" "}
                                | {item.additionalInfo}
                              </span>
                            ) : null}
                          </div>
                          <div className={classes.moreLinkWrapper}>
                            <div className="more-link-block">
                              <span
                                className="more-link"
                                aria-controls="menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, index)}
                              >
                                <MoreVertIcon />
                              </span>

                              <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={getIndex === index && Boolean(anchorEl)}
                                onClose={handleCloseSidebar}
                                className={classes.customMenu}
                                menuprops={{
                                  classes: { paper: classes.customMenu },
                                }}
                              >
                                <MenuItem onClick={() => handleEditOpen(item)}>
                                  View
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              )}
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default ConsignmentsData;
