import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
} from "@material-ui/core";


import closeIcon from "@assets/images/close.svg";

import { AddPopupStyle } from "./style";

function PopupShowToken(props) {
    const classes = AddPopupStyle();
    const [scroll] = useState("body");

    return (
        <>
            <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
                <div className="close-modal">
                    <img src={closeIcon} alt="Close" onClick={props.handleClose} />
                </div>
                <DialogTitle>Token</DialogTitle>
                <DialogContent>
                    <div className="form-row">
                        <div className="form-group">
                            <FormControl variant="outlined" className={classes.formControl}>
                                <TextField
                                    id="token"
                                    variant="outlined"
                                    autoComplete="off"
                                    type="text"
                                    value={props?.token}
                                    InputProps={{ readOnly: true }}
                                />
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="bottom-button-block">
                    <Button
                        className="primary-btn gray-border-btn"
                        color="inherit"
                        disableElevation
                        underlinenone="true"
                        onClick={props.handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="orange-btn primary-btn"
                        color="inherit"
                        disableElevation
                        underlinenone="true"
                        onClick={() => props.handleCopy(props?.token)}
                    >
                        Copy
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
export default PopupShowToken;
