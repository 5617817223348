import React, {  useState } from "react";
import { DamageReportStyle } from "./style";
import {Tab, Tabs} from "@material-ui/core";

function DamageReports(props) {
  const classes = DamageReportStyle();
  const [value, setValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  function TabPanel(props) {
    const { children, value, index } = props;
    return (
      value === index && <div className="tab-pannel-wrapper enable-scroll"> {children} </div>
    );
  }

  props.jobsData.airWaybills = props.jobsData.airWaybills.filter(item => {
    return item.damageReports && item.damageReports.length;
  })

  return (
    <div className={classes.DamageReportWrapper}>
      <Tabs
        value={value}
        onChange={handleChangeTab}
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className="tabs"
      >
        {
          props.jobsData.airWaybills?.map((item, index) => {
            return (
              <Tab label={item.number}/>
            )
          })
        }

      </Tabs>
      {
        props.jobsData.airWaybills?.map((item, indexAirwayBill) => {
          return (
            <TabPanel value={value} index={indexAirwayBill}>
              {item.damageReports?.map((report, index) => {
                return (
                  <div className="container-holder">
                    <div className="image"><img src={report.photo} alt=""/></div>
                    <div className="text">{report.note}</div>
                  </div>
                )
              })}
            </TabPanel>
          )
        })
      }
    </div>
  );
}
export default DamageReports;
