import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import DetailFields from "./detailFields";
import DangerousFields from "./dangerousFields";
import { AddConsignmentStyle } from "./style";

function AddConsignment(props) {
  const classes = AddConsignmentStyle();
  const [scroll] = useState("body");
  const [state] = useStore();

  return (
    <div className={classes.AddConsignmentWrapper}>
      {["Created", "Acknowledged"].includes(
        props.onlineData?.onlineRequestStatuses?.name
      ) && (
        <Button
          className="orange-btn primary-btn"
          color="inherit"
          disableElevation
          underlinenone="true"
          onClick={props.handleClickOpen}
        >
          + Add consignment
        </Button>
      )}

      <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className={classes.customForm}
          onSubmit={props.formik.handleSubmit}
        >
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DialogTitle>
            {props.isEdit
              ? ["Created", "Acknowledged"].includes(
                  props.onlineData?.onlineRequestStatuses?.name
                )
                ? "Edit"
                : "View"
              : "Add"}{" "}
            consignment
          </DialogTitle>
          <DialogContent>
            <DetailFields
              formik={props.formik}
              handleImage={props.handleImage}
              onlineData={props.onlineData}
            />
            <DangerousFields formik={props.formik} />
          </DialogContent>
          <DialogActions className="bottom-button-block">
            <Button
              className="primary-btn gray-border-btn"
              color="inherit"
              disableElevation
              underlinenone="true"
              onClick={props.handleClose}
            >
              CANCEL
            </Button>
            {["Created", "Acknowledged"].includes(
              props.onlineData?.onlineRequestStatuses?.name
            ) && (
              <Button
                className="orange-btn primary-btn"
                color="inherit"
                disableElevation
                underlinenone="true"
                type="submit"
                disabled={
                  state?.onlineRequest?.addingOnlineRequestConsignment ||
                  state?.onlineRequest?.uploadingImage ||
                  state?.onlineRequest?.editingOnlineRequestConsignment
                }
              >
                {state?.onlineRequest?.addingOnlineRequestConsignment ||
                state?.onlineRequest?.uploadingImage ||
                state?.onlineRequest?.editingOnlineRequestConsignment ? (
                  <CircularProgress color="inherit" />
                ) : props.isEdit ? (
                  "Edit"
                ) : (
                  "Add"
                )}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default AddConsignment;
