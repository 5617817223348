import { makeStyles } from "@material-ui/core/styles";

import { colors } from "./constant";

const materialCommonStyles = makeStyles((theme) => ({
  customSelect: {
    "& .MuiSvgIcon-root": {
      position: "absolute",
      right: "7px",
      top: "calc(50% - 12px)",
      pointerEvents: "none",
      cursor: "text",
    },
    "& li": {
      fontSize: "16px",
      padding: "10px 15px",
      "&.MuiListItem-root.Mui-focusVisible:hover": {
        backgroundColor: "rgba(247, 146, 57, 0.2)",
      },
      "&.MuiListItem-root.Mui-focusVisible": {
        backgroundColor: "transparent",
      },
      "&.Mui-disabled": {
        display: "none",
      },
      "&:hover": {
        color: colors.orange,
        backgroundColor: "rgba(247, 146, 57, 0.2)",
      },
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: colors.orange + "! important",
        color: colors.white,
        fontWeight: "600",
      },
      "& .MuiListItemText-root .MuiTypography-body1": {
        fontSize: "16px",
        lineHeight: "1.3",
      },
    },
  },
  customTooltip: {
    borderRadius: "6px",
    boxShadow: "0 4px 10px 0 rgba(97, 97, 97, 0.2)",
    backgroundColor: "#ffffff",
    padding: "18px",
    "& .MuiTooltip-arrow": {
      color: colors.white,
    },
    "& ul": {
      "& li": {
        "& + li": {
          marginTop: "10px",
        },
        fontSize: "14px",
        lineHeight: 1.07,
        color: "#646464",
        "& span": {
          fontWeight: "500",
          color: colors.black,
        },
      },
    },
  },
  modalTooltip: {
    borderRadius: "6px",
    boxShadow: "0 4px 10px 0 rgba(97, 97, 97, 0.2)",
    backgroundColor: "#ffffff",
    padding: "8px 32px 8px 8px",
    maxWidth: "522px",
    "& .MuiTooltip-arrow": {
      color: colors.white,
    },
    "& p": {
      color: colors.black,
      fontSize: "13px",
    },
  },
  autoComplete: {
    "&.MuiMenuItem-root": {
      fontSize: "16px",
      width: "100%",
      "&.MuiListItem-root.Mui-focusVisible:hover": {
        // backgroundColor: "transparent",
        backgroundColor: "rgba(247, 146, 57, 0.2)",
        width: "100%",
      },
      "&:hover": {
        color: colors.orange,
        backgroundColor: "transparent",
        width: "100%",
      },
    },
    "& .MuiInputBase-input": {
      border: "0",
      height: "36px",
      marginTop: "-7px",
      color: "#686868",
    },
    "& .MuiInputBase-root": {
      boxShadow: "0 3px 8px 0 rgb(97 97 97 / 5%)",
      backgroundColor: colors.white,
      minHeight: "45px",
      "& .MuiChip-root": {
        backgroundColor: "transparent",
        "& .MuiChip-deleteIcon": {
          width: 0,
          height: 0,
        },
        "& .MuiChip-label": {
          padding: 0,
        },
      },
    },
  },
}));

export { materialCommonStyles };
