import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Typography, Button } from "@material-ui/core";

import { useStore } from "@store/store";
import DownloadImage from "@assets/images/download.svg";
import {
  GET_JOB_RUNSHEET,
  GET_JOB_RUNSHEET_SUCCESS,
  GET_JOB_RUNSHEET_FAILURE,
  GET_STREET_BY_STREET,
  GET_STREET_BY_STREET_SUCCESS,
  GET_STREET_BY_STREET_FAILURE,
  DOWNLOAD_PDF_JOB_RUNSHEET,
  DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS,
  DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE,
  DOWNLOAD_CSV_JOB_RUNSHEET,
  DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS,
  DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE,
} from "@utils/actionTypes";
import { getApi } from "@services/axios";
import TableListing from "./table-listing";
import { TimeReportStyle } from "./style";
import Map from "./map";

function TimeReport(props) {
  const classes = TimeReportStyle();
  const [state, dispatch] = useStore();
  const { id } = useParams();

  // API calling to get list of job runsheet
  const getJobRunsheet = () => {
    dispatch({ type: GET_JOB_RUNSHEET });
    getApi(`jobs/${id}/jobRunsheetReport`, null, false)
      .then((response) => {
        dispatch({
          type: GET_JOB_RUNSHEET_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({type: GET_JOB_RUNSHEET_FAILURE, payload: error});
        if (error?.response?.data?.code !== 404) {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const getStreetByStreetTracking = () => {
    dispatch({ type: GET_STREET_BY_STREET });
    getApi(`jobs/${id}/streetByStreetTracking`, null, false)
        .then((response) => {
          dispatch({
            type: GET_STREET_BY_STREET_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_STREET_BY_STREET_FAILURE, payload: error });
        });
    };

  useEffect(() => {
    getJobRunsheet();
    if(props.jobsData?.isAuto) {
      getStreetByStreetTracking();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API calling to download CSV file
  const handleDownloadCsv = () => {
    dispatch({ type: DOWNLOAD_CSV_JOB_RUNSHEET });
    getApi(`jobs/${id}/downloadJobRunsheetCSV`, null, false)
      .then((response) => {
        dispatch({
          type: DOWNLOAD_CSV_JOB_RUNSHEET_SUCCESS,
          payload: response.data.data,
        });
        window.location.href = response.data.data;
      })
      .catch((error) => {
        dispatch({ type: DOWNLOAD_CSV_JOB_RUNSHEET_FAILURE, payload: error });
        toast.error(error?.response?.data?.message);
      });
  };

  // API calling to download PDF file
  const handleDownloadPdf = () => {
    dispatch({ type: DOWNLOAD_PDF_JOB_RUNSHEET });
    getApi(`jobs/${id}/downloadJobRunsheetPDF`, null, false)
      .then((response) => {
        dispatch({
          type: DOWNLOAD_PDF_JOB_RUNSHEET_SUCCESS,
          payload: response.data.data,
        });
        window.open(response.data.data, "_blank");
      })
      .catch((error) => {
        dispatch({ type: DOWNLOAD_PDF_JOB_RUNSHEET_FAILURE, payload: error });
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <div className={classes.TimeReportWrapper}>
      {state?.job?.jobRunSheetData?.jobRunsheetReport?.length && (
        <div className="map-wrapper">
          <Map jobsData={props.jobsData}/>
        </div>
      )}

      {state?.job?.jobRunSheetData?.jobRunsheetReport?.length !== 0 && (
        <div className={classes.tabHeadingRow}>
          <div className={classes.titleWrapper}>
            <Typography variant="h2">Run Sheet</Typography>
          </div>
          {["Review Completed", "Completed"].includes(
            props.jobsData?.jobStatuses?.name
          ) && (
            <div className={classes.buttonWrapper}>
              <div className="btn-inner-wrapper">
                <Button
                  className="blue-btn primary-btn"
                  color="inherit"
                  disableElevation
                  underlinenone="true"
                  onClick={handleDownloadCsv}
                >
                  <img src={DownloadImage} alt="Download" />
                  Download csv
                </Button>
                <Button
                  className="blue-btn primary-btn"
                  color="inherit"
                  disableElevation
                  underlinenone="true"
                  onClick={handleDownloadPdf}
                >
                  <img src={DownloadImage} alt="Download" />
                  Download pdf
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <TableListing runSheetData={props.runSheetData} />
    </div>
  );
}
export default TimeReport;
