import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { useFormik } from "formik";

import { LOGIN, LOGIN_SUCCESS, LOGIN_FAILURE } from "@utils/actionTypes";
import { getEmail, getParentData, getLogin, getToken } from "@utils/commonFunctions";
import { bannerStyle } from "@utils/commonStyles";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { routes } from "@utils/constant";
import Header from "@components/header";
import Footer from "@components/footer";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import { getApi } from "@services/axios";
import { useStore } from "@store/store";

function ParentCustomer() {
  const classes = bannerStyle();
  const materilClasses = materialCommonStyles();
  const history = useHistory();
  const [, dispatch] = useStore();
  const email = getEmail();
  const parentData = getParentData();
  const isLogin = getLogin();
  const isToken = getToken();

  useEffect(() => {
    if (!isLogin) {
      history.push(routes.login);
    }
    if(isToken){
      history.push(routes.dashboard)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const menuData =
    parentData &&
    parentData.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });

  const formik = useFormik({
    initialValues: schema.selectParentSchema,
    validationSchema: validationSchema.selectParentValidationSchema,
    onSubmit: (value) => {
      // API calling to get parent accounts
      dispatch({ type: LOGIN });
      getApi(`parentAccounts/${value.parentId}?email=${email}`, null, false)
        .then((response) => {
          dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data,
          });
          history.push(routes.welcome);
        })
        .catch((error) => {
          dispatch({
            type: LOGIN_FAILURE,
            payload: error?.response?.data,
          });
        });
    },
  });

  return (
    <>
      <Header />
      <div className={classes.commonBannerWrapper}>
        <div className="commonBanner">
          <div className="white-box-wrapper">
            <div className="white-box">
              <div className="title-wrapper">
                <Typography variant="h1">Welcome to Wymap</Typography>
                <p>
                  Please select one parent customer to get the filtered
                  information.
                </p>
              </div>
              <form
                noValidate
                autoComplete="off"
                className="custom-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-row">
                  <div className="form-gourp">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="division">Division</InputLabel>
                      <Select
                        labelId="division"
                        onChange={formik.handleChange("parentId")}
                        value={formik.values.parentId}
                        displayEmpty
                        label="Division"
                        className={materilClasses.customSelect}
                        MenuProps={{
                          classes: { paper: materilClasses.customSelect },
                        }}
                        IconComponent={() => <ExpandMore />}
                      >
                        <MenuItem value="" disabled>
                          Select Parent Customer
                        </MenuItem>
                        {menuData?.map((item, index) => {
                          return (
                            <MenuItem key={index} value={item.value}>
                              {item.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText className="error-text">
                        {formik.touched.parentId && formik.errors.parentId}
                      </FormHelperText>
                    </FormControl>
                  </div>
                </div>
                <div className="form-row btn-wrapper">
                  <Button
                    className="orange-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    // disabled={division.length === 0}
                  >
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ParentCustomer;
