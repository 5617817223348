import React from "react";

import Popup from "../popup";
import { AddNewLooseStyle } from "./style";

function AddNewLoose(props) {
  const classes = AddNewLooseStyle();

  return (
    <div className={classes.AddNewItemWrapper}>
      <Popup
        formik={props.formik}
        open={props.open}
        handleClose={props.handleClose}
      />
    </div>
  );
}
export default AddNewLoose;
