import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";

import { TabbingStyle } from "./style";
import AirWayBill from "./air-waybills";
import Consignments from "./consignments";
import TimeReport from "./time-report";
import PriceMatrix from "./pricing";
import DamageReports from "./damage-reports";
import { jobStatus } from "@utils/constant";

function Tabbing(props) {
	const classes = TabbingStyle();
	const [value, setValue] = useState(0);
	const [showDamageReports, setShowDamageReports] = useState(false);
	const [isLoadedJobsData, setIsLoadedJobsData] = useState(false);

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};
	function TabPanel(props) {
		const { children, value, index } = props;
		return (
			value === index && <div className="tab-pannel-wrapper"> {children} </div>
		);
	}

	// Check Existed Damage Reports of Airwaybills in Job Detail
	if (!isLoadedJobsData) {
		if (props.jobsData && Object.keys(props.jobsData).length > 0) {
			const jobStatusDriver = [
				jobStatus.NOT_ASSIGNED,
				jobStatus.REJECTED,
			].includes(props.jobsData?.jobStatuses?.name);

			if (props.jobsData.drivers && !jobStatusDriver) {
				if (props.jobsData.airWaybills.length) {
					for (let i = 0; i < props.jobsData.airWaybills.length; i++) {
						if (props.jobsData.airWaybills[i].damageReports && props.jobsData.airWaybills[i].damageReports.length) {
							setShowDamageReports(true);
							break;
						}
					}
				} else {
					setShowDamageReports(false);
				}
			} else {
				setShowDamageReports(false);
			}
			setIsLoadedJobsData(true);
		}
	}

	return (
		<div className={classes.TabbingWrapper}>
			<div className="white-card tabbing-wrapper">
				<Tabs
					value={value}
					onChange={handleChangeTab}
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
				>
					{props.jobsData?.jobTypes?.name === "Empty" ? (
						""
					) : ["Import", "Export", "Temp Control"].includes(
							props.jobsData?.jobTypes?.name
					  ) ? (
						<Tab label={`Air waybills`} />
					) : (
						<Tab label={`Consignments`} />
					)}
					<Tab label="Run Sheet" />

					{/* {["Review Completed", "Completed"].includes(
            props.jobsData?.jobStatuses?.name
          ) && <Tab label="Pricing" />} */}

					{
						showDamageReports? (<Tab label="Damage Reports" />):""
					}
				</Tabs>
				<TabPanel value={value} index={0}>
					{props.jobsData?.jobTypes?.name === "Empty" ? (
						""
					) : ["Import", "Export", "Temp Control"].includes(
							props.jobsData?.jobTypes?.name
					  ) ? (
						<AirWayBill jobsData={props.jobsData} />
					) : (
						<Consignments jobsData={props.jobsData} />
					)}
				</TabPanel>
				<TabPanel
					value={value}
					index={props.jobsData?.jobTypes?.name === "Empty" ? 0 : 1}
				>
					<TimeReport jobsData={props.jobsData} />
				</TabPanel>
				{
					showDamageReports? (
						<TabPanel
							value={value}
							index={
								!["Not Assigned", "Rejected"].includes(
									props.jobsData?.jobStatuses?.name
								)
									? props.jobsData?.jobTypes?.name === "Empty"
										? 1 : 2
									: 1}
						>
							<DamageReports jobsData={props.jobsData} getJobs={props.getJobs}/>
						</TabPanel>
					) : ""
				}
			</div>
		</div>
	);
}
export default Tabbing;
