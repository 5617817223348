import React, { useState, useEffect } from "react";
import { useHistory, Link, NavLink, useLocation } from "react-router-dom";
import { Button, AppBar } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import cx from "classnames";

import siteLogo from "@assets/images/logo-white.svg";
import backArrow from "@assets/images/back-arrow.svg";
import call from "@assets/images/call.svg";
import HomeIcon from "@assets/images/home-icon.svg";
import {
	headerTop,
	getToken,
	getAccess,
	removeFilter,
	setFilter,
} from "@utils/commonFunctions";
import { routes } from "@utils/constant";
import MenuPopup from "./menuPopup";
import { headerStyle } from "./style";

function Header() {
	const location = useLocation();
	const history = useHistory();
	const classes = headerStyle();
	const [activeRoute, setActiveRoute] = useState("");

	// for profile menu
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		setActiveRoute(location.pathname);
		if (!location.pathname.includes("online-request")) {
			removeFilter("onlineRequestFilter");
		}
		if (!location.pathname.includes("job")) {
			removeFilter("jobFilter");
			removeFilter("jobDashboardFilter");
			removeFilter("jobAllocate");
			removeFilter("cargoVolume");
		}
	}, [location]);

	// for header padding
	window.addEventListener("load", function () {
		setTimeout(() => {
			headerTop();
		}, 150);
	});
	window.addEventListener("resize", function () {
		setTimeout(() => {
			headerTop();
		}, 150);
	});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	// for mobile menu
	const openMenu = () => {
		document.body.classList.toggle("open-menu");
	};

	// for profile menu
	const handleClose = (val) => {
		setAnchorEl(null);
		document.body.classList.remove("open-profile-menu");
		history.push(val);
	};

	const handleEnter = () => {
		document.body.classList.add("open-profile-menu");
	};

	// sub menu
	const subMenu = (event) => {
		event.preventDefault();
		if (window.innerWidth < 992) {
			var parentElement = event.target.closest("li.has-submenu");
			var element = document.querySelectorAll("li.has-submenu");
			for (let i = 0; i < element.length; i++) {
				if (element[i] === parentElement) {
					element[i].classList.toggle("open-submenu");
				} else {
					element[i].classList.remove("open-submenu");
				}
			}
		}
	};
	const handleAWBNumberClick = (event) => {
		event.preventDefault();
		setFilter("reportTab", 3);
		history.push(routes.report);
	};

	return (
		<div className={classes.headerWrapper}>
			<AppBar className="site-header" id="header">
				<div className="header-wrapper">
					<Link to={routes.dashboard} className="site-logo" title="Wymap">
						<img src={siteLogo} alt="Wymap" />
					</Link>
					<nav className="main-nav-wrapper">
						{getToken() && getAccess() ? (
							<div className="inner-nav-wrapper">
								<div className="main-nav-inner-wrapper">
									<ul className="main-nav">
										<li>
											<NavLink
												to={routes.dashboard}
												className={cx({
													active:
														activeRoute === "/" ||
														activeRoute === routes.dashboard,
												})}
											>
												Dashboard
											</NavLink>
										</li>
										{
											<li>
												<NavLink
													to={routes.AWBSearch}
													className={cx({
														active: activeRoute === routes.AWBSearch,
													})}
													onClick={handleAWBNumberClick}
												>
													AWB Search
												</NavLink>
											</li>
										}
										<li>
											<NavLink to={routes.mapCockpit}>Map/Cockpit</NavLink>
										</li>
										<li className="has-submenu">
											<NavLink
												onClick={subMenu}
												to={routes.onlineRequest}
												className={cx({
													active:
														activeRoute === routes.onlineRequest ||
														activeRoute === routes.jobListing ||
														activeRoute.includes(routes.jobDetail) ||
														activeRoute.includes(routes.onlinRequestDetail),
												})}
											>
												Jobs
											</NavLink>
											<ExpandMore className="submenu-arrow" />
											<ul className="sub-menu">
												<li>
													<NavLink to={routes.jobListing}>Jobs</NavLink>
												</li>
												<li>
													<NavLink to={routes.onlineRequest}>
														Online Request
													</NavLink>
												</li>
											</ul>
										</li>
										{/* <li>
                      <NavLink
                        to={routes.onlineRequest}
                        className={cx({
                          active:
                            activeRoute === routes.onlineRequest ||
                            activeRoute.includes(routes.onlinRequestDetail),
                        })}
                      >
                        Online Request
                      </NavLink>
                    </li> */}
										<li>
											<NavLink to={routes.report}>Report</NavLink>
										</li>
										<li>
											<NavLink to={routes.invoice}>Invoice</NavLink>
										</li>
										<li>
											<NavLink to={routes.upload}>
												Upload AWB/Consignment
											</NavLink>
										</li>
									</ul>
								</div>
								<div className="profile-nav-wrapper">
									<MenuPopup
										handleClick={(e) => handleClick(e)}
										handleClose={(val) => handleClose(val)}
										classes={classes}
										anchorEl={anchorEl}
										handleEnter={handleEnter}
									/>
									<div className="mob-nav-icon" onClick={openMenu}>
										<span></span>
									</div>
								</div>
							</div>
						) : (
							<div className="home-nav-wrapper">
								<ul>
									<li>
										<Button
											className="light-blue-btn"
											variant="contained"
											color="primary"
											disableElevation
											title="Back to Wymap"
										>
											<img
												className="back-icon"
												src={backArrow}
												alt="Back to Wymap"
											/>
											<img
												className="home-icon"
												src={HomeIcon}
												alt="Back to Wymap"
											/>
											Back to Wymap
										</Button>
									</li>
									<li>
										<Button
											href="tel:+15182824642"
											className="orange-btn"
											color="inherit"
											title="Call Us"
											disableElevation
										>
											<img src={call} alt="Call" />
											1300 WYMAP1
										</Button>
									</li>
								</ul>
							</div>
						)}
					</nav>
				</div>
			</AppBar>
		</div>
	);
}

export default Header;
