import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const tableStyles = makeStyles({
  customTable: {
    marginTop: 25,
    maxHeight: 680,
    boxShadow: "none",
    "@media (max-height: 800px)": {
      maxHeight: 410,
    },
    "& .MuiTable-root": {
      minWidth: 1300,
      "& .MuiTableCell-root": {
        fontWeight: 500,
        color: colors.black,
        border: "none",
      },
      "& .MuiTableHead-root": {
        boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
        backgroundColor: colors.white,
        "& .MuiTableCell-root": {
          color: "rgba(33, 33, 33, 0.7)",
          padding: "30px 10px",
          backgroundColor: colors.white,
          borderBottom: "1px solid #f4f4f4",
          "&:first-child": {
            paddingLeft: "30px",
          },
          "&:last-child": {
            paddingRight: "30px",
          },
          "&.job-status": {
            width: 178,
          },
          "&.location": {
            width: 150,
          },
        },
      },
      "& .MuiTableBody-root": {
        "& .MuiTableCell-root": {
          padding: "22px 10px",
          "&:first-child": {
            paddingLeft: "30px",
          },
          "&:last-child": {
            paddingRight: "30px",
          },
          "&.request-status": {
            "& span": {
              position: "relative",
              paddingLeft: 20,
              display: "block",
              "&:before": {
                content: '""',
                position: "absolute",
                backgroundColor: colors.black,
                height: 10,
                width: 10,
                borderRadius: "50%",
                left: 0,
                top: 7,
              },
            },
            "&.requested": {
              "& span": {
                color: colors.green,
                "&:before": {
                  backgroundColor: colors.green,
                },
              },
            },
            "&.acknowledged": {
              "& span": {
                color: colors.red1,
                "&:before": {
                  backgroundColor: colors.red1,
                },
              },
            },
            "&.driver-assigned": {
              "& span": {
                color: colors.yellow1,
                "&:before": {
                  backgroundColor: colors.yellow1,
                },
              },
            },
            "&.request-completed": {
              "& span": {
                color: colors.green1,
                "&:before": {
                  backgroundColor: colors.green1,
                },
              },
            },
          },
        },
      },
    },
  },
  tableWrapper: {
    boxShadow: "0 3px 8px 0 rgba(97, 97, 97, 0.08)",
    backgroundColor: colors.white,
    borderRadius: "6px",
    "& .pagination-wrapper": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      overflow: "hidden",
      "& .MuiTablePagination-root": {
        padding: "10px 0 10px 30px !important",
      },
    },
  },
});

export { tableStyles };
