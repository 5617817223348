import {
  CARGO_VOLUME,
  RESET_REDIRECTION,
  DASHBOARD_FILTER_REDIRECTION,
  JOBS_STATUS_REDIRECTION,
} from "@utils/actionTypes";

const INIT_STATE = {
  cargoVolume: "",
  filterRedirection: null,
  jobStatusRedirection: "",
};
const redirectionReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CARGO_VOLUME:
      return { ...state, cargoVolume: action.payload };

    case DASHBOARD_FILTER_REDIRECTION:
      return { ...state, filterRedirection: action.payload };

    case JOBS_STATUS_REDIRECTION:
      return { ...state, jobStatusRedirection: action.payload };

    case RESET_REDIRECTION:
      return INIT_STATE;

    default:
      return state;
  }
};
export default redirectionReducer;
