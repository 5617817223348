import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Typography,
  TextField,
  Button,
  FormHelperText,
  Checkbox
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import ProfileSideBar from "@components/profile-sidebar";
import Header from "@components/header";
import Loader from "@components/loader";
import EditIcon from "@assets/images/edit-icon.svg";
import siteLogo from "@assets/images/logo-wymap-1.png";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import { uploadUserImage, allowAlphaNumeric } from "@utils/commonFunctions";
import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  SET_PROFILE,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_FAILURE,
  IMAGE_UPLOAD,
  IMAGE_UPLOAD_SUCCESS,
  IMAGE_UPLOAD_FAILURE,
} from "@utils/actionTypes";
import { getApi, putApi } from "@services/axios";
import { myProfileStyle } from "./style";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";

function MyProfileView() {
  const classes = myProfileStyle();
  const [err, setErr] = useState("");
  const [data, setData] = useState(schema.myProfileSchema);
  const [avatar, setAvatar] = useState(siteLogo);
  const [imageErr, setImageErr] = useState("");
  const [state, dispatch] = useStore();
  const [email, setEmail] = useState("");

  // API calling to get user profile data
  const getUser = () => {
    dispatch({ type: GET_PROFILE });
    getApi("users/me", null, false)
      .then((response) => {
        setData({
          name: response.data.data?.name,
          email: response.data.data?.email,
          profileImage: response.data.data?.profileImage
            ? response.data.data?.profileImage
            : avatar,
          isCargoWise: response.data.data?.customers?.isCargoWise,
          customerParentIds: response.data.data?.customerParentIds

        });
        dispatch({ type: GET_PROFILE_SUCCESS, payload: response.data });
        setEmail(response.data?.data?.email);
      })
      .catch((error) => {
        dispatch({ type: GET_PROFILE_FAILURE, payload: error.response.data });
      });
  };

  const setUser = (data) => {
    dispatch({ type: SET_PROFILE });
    putApi("users/me", data)
      .then((response) => {
        dispatch({ type: SET_PROFILE_SUCCESS, payload: response.data });
        getUser();
        setErr("");
        setAvatar("");
        if (email !== data.email) {
          toast.success("Email Verification Link Has Been Sent");
        } else {
          toast.success("Profile Updated Successfully");
        }
        data.email = email;
      })
      .catch((error) => {
        dispatch({
          type: SET_PROFILE_FAILURE,
          payload: error.response.data,
        });
        setErr(error.response.data.message);
        toast.error(error.response.data.message);
      });
  };
  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: validationSchema.myProfileValidationSchema,
    onSubmit: (values) => {
      if (typeof avatar === "string") {
        const data = {
          name: values.name,
          email: values.email,
          isCargoWise: values.isCargoWise
        };
        setUser(data);
      } else {
        dispatch({
          type: IMAGE_UPLOAD,
        });
        uploadUserImage(avatar, avatar.target.files[0]?.type, "user-logo")
          .then((res) => {
            const data = {
              name: values.name,
              email: values.email,
              isCargoWise: values.isCargoWise,
              profileImage: res.data.fileName,
            };
            setUser(data);
            dispatch({
              type: IMAGE_UPLOAD_SUCCESS,
            });
          })
          .catch((err) => {
            dispatch({
              type: IMAGE_UPLOAD_FAILURE,
            });
            toast.error("Image Uploading Failed");
          });
      }
    },
  });

  function handleChange(event) {
    if (event.currentTarget.files[0].size >= 11534336) {
      setImageErr(
        "File Size is too large, File size must be 10 MB or below 10 MB"
      );
    } else {
      setImageErr("");
      setAvatar(event);
    }
    let reader = new FileReader();
    reader.onload = function () {
      setData({
        ...data,
        profileImage: reader.result,
      });
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  return (
    <>
      <Header />
      <div className={classes.profileWrapper}>
        <Loader
          loading={
            state.profile.gettingProfile ||
            state.profile.settingProfile ||
            state?.common?.imageUploading
          }
        />
        <div className="wrapper">
          <div className="container">
            <div className="profile-row-wrapper">
              <div className="left-sidebar">
                <ProfileSideBar />
              </div>
              <div className="right-content">
                <div className="white-card">
                  <div className="alert">
                    {err && <Alert severity="error">{err}</Alert>}
                  </div>
                  <Typography variant="h1">My Profile</Typography>
                  <form
                    noValidate
                    autoComplete="off"
                    className="custom-form"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="form-row">
                      <FormHelperText className="error-text">
                        {imageErr && imageErr}
                      </FormHelperText>
                      <div className="upload-img-wrapper">
                        <div className="img-block">
                          <img
                            src={formik.values.profileImage}
                            alt="edit-icon"
                          ></img>
                        </div>
                        <div className="form-gourp">
                          <input
                            type="file"
                            id="profileImage"
                            accept="image/x-png,image/jpeg"
                            label="avatar"
                            onChange={handleChange}
                          />
                          <img src={EditIcon} alt="edit-icon"></img>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-gourp">
                        <TextField
                          id="name"
                          label="Name"
                          placeholder="Name"
                          name="name"
                          variant="outlined"
                          onKeyPress={allowAlphaNumeric}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-gourp">
                        <TextField
                          id="email"
                          label="Email"
                          placeholder="Email"
                          name="email"
                          variant="outlined"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-gourp">
                        <div className="div-wrapper">
                          <div>
                            <Checkbox
                            disabled={formik.values.customerParentIds}
                            name="isCargoWise"
                            onChange={formik.handleChange("isCargoWise")}
                            icon={<img src={uncheckedIcon} alt="CheckBox" />}
                            checkedIcon={<img src={checkedIcon} alt="CheckBox" />}
                            checked={formik.values.isCargoWise} />
                            <span className="label-tex">CargoWise user</span>
                          </div>
                        </div>
                      </div>
                  </div> 
                    <div className="form-row btn-wrapper">
                      <Button
                        className="orange-btn lg"
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="submit"
                        disabled={imageErr !== ""}
                      >
                        SAVE CHANGES
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyProfileView;
