import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAILURE,
  SET_PROFILE,
  SET_PROFILE_SUCCESS,
  SET_PROFILE_FAILURE,
  PROFILE_VERIFY_EMAIL,
  PROFILE_VERIFY_EMAIL_SUCCESS,
  PROFILE_VERIFY_EMAIL_FAILURE,
  GENERATE_TOKEN,
  GENERATE_TOKEN_SUCCESS,
  GENERATE_TOKEN_FAILURE,
  FETCH_PERMISSION_INTEGRATION,
  FETCH_PERMISSION_INTEGRATION_SUCCESS,
  FETCH_PERMISSION_INTEGRATION_FAILURE,
  FETCH_INTEGRATION_API_PERMISSION,
  FETCH_INTEGRATION_API_PERMISSION_SUCCESS,
  FETCH_INTEGRATION_API_PERMISSION_FAILURE,
  FETCH_CUSTOMER_INTEGRATION_TOKEN,
  FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS,
  FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE
} from "@utils/actionTypes";

const INIT_STATE = {
  gettingProfile: false,
  getProfileData: null,
  settingProfile: false,
  setProfileData: null,
  verifyingProfileEmail: false,
  verifiedProfileEmailData: null,
  generatingToken: false,
  loadingPermissionIntegration: false,
  permissionIntegrationList: null,
  loadingIntegrationApiPermission: false,
  integrationApiPermissionList: null,
  customerIntegrationTokenData: null,
  loadingCustomerIntegrationToken: false,
};

const ProfileReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return { ...state, gettingProfile: true };
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        getProfileData: action.payload,
        gettingProfile: false,
      };
    case GET_PROFILE_FAILURE:
      return {
        ...state,
        getProfileData: action.payload,
        gettingProfile: false,
      };

    case SET_PROFILE:
      return { ...state, settingProfile: true };
    case SET_PROFILE_SUCCESS:
      return {
        ...state,
        setProfileData: action.payload,
        settingProfile: false,
      };
    case SET_PROFILE_FAILURE:
      return {
        ...state,
        setProfileData: action.payload,
        settingProfile: false,
      };

    case PROFILE_VERIFY_EMAIL:
      return { ...state, verifyingProfileEmail: true };
    case PROFILE_VERIFY_EMAIL_SUCCESS:
      return {
        ...state,
        verifiedProfileEmailData: action.payload,
        verifyingProfileEmail: false,
      };
    case PROFILE_VERIFY_EMAIL_FAILURE:
      return {
        ...state,
        verifiedProfileEmailData: action.payload,
        verifyingProfileEmail: false,
      };

    case GENERATE_TOKEN:
      return { ...state, generatingToken: true };
    case GENERATE_TOKEN_SUCCESS:
      return { ...state, generatingToken: false };
    case GENERATE_TOKEN_FAILURE:
      return { ...state, generatingToken: false };

    case FETCH_PERMISSION_INTEGRATION:
      return { ...state, loadingPermissionIntegration: true };
    case FETCH_PERMISSION_INTEGRATION_SUCCESS:
      return {
        ...state,
        permissionIntegrationList: action.payload,
        loadingPermissionIntegration: false,
      };
    case FETCH_PERMISSION_INTEGRATION_FAILURE:
      return {
        ...state,
        permissionIntegrationList: action.payload,
        loadingPermissionIntegration: false,
      };

    case FETCH_INTEGRATION_API_PERMISSION:
      return { ...state, loadingIntegrationApiPermission: true };
    case FETCH_INTEGRATION_API_PERMISSION_SUCCESS:
      return {
        ...state,
        integrationApiPermissionList: action.payload,
        loadingIntegrationApiPermission: false,
      };
    case FETCH_INTEGRATION_API_PERMISSION_FAILURE:
      return {
        ...state,
        integrationApiPermissionList: action.payload,
        loadingIntegrationApiPermission: false,
      };

    case FETCH_CUSTOMER_INTEGRATION_TOKEN:
      return { ...state, loadingCustomerIntegrationToken: true };
    case FETCH_CUSTOMER_INTEGRATION_TOKEN_SUCCESS:
      return {
        ...state,
        customerIntegrationTokenData: action.payload,
        loadingCustomerIntegrationToken: false,
      };
    case FETCH_CUSTOMER_INTEGRATION_TOKEN_FAILURE:
      return {
        ...state,
        customerIntegrationTokenData: action.payload,
        loadingCustomerIntegrationToken: false,
      };

    default:
      return state;
  }
};
export default ProfileReducer;
