import {
  FETCH_DRIVERS,
  FETCH_DRIVERS_SUCCESS,
  FETCH_DRIVERS_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  gettingDrivers: false,
  getDriversData: null,
};

const DriverReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_DRIVERS:
      return { ...state, gettingDrivers: true };
    case FETCH_DRIVERS_SUCCESS:
      return {
        ...state,
        getDriversData: action.payload,
        gettingDrivers: false,
      };
    case FETCH_DRIVERS_FAILURE:
      return {
        ...state,
        getDriversData: action.payload,
        gettingDrivers: false,
      };
    default:
      return state;
  }
};
export default DriverReducer;
