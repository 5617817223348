import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  GET_DASHBOARD_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingDashboard: false,
  dashboardData: null,
};

const dashboardReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD:
      return { ...state, loadingDashboard: true };
    case GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: action.payload,
        loadingDashboard: false,
      };
    case GET_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardData: action.payload,
        loadingDashboard: false,
      };

    default:
      return state;
  }
};
export default dashboardReducer;
