import { makeStyles } from "@material-ui/core";

const DamageReportStyle = makeStyles({
  DamageReportWrapper: {
    marginTop: "-5px",
    "& .tabs": {
      borderBottom: "0 !important"
    },
    "& .container-holder": {
      display: "flex",
      alignItems: "normal",
      justifyContent: "normal",
      marginBottom: "25px",
      "& .image": {
        flex: 3
      },
      "& .text": {
        flex: 1,
        paddingLeft: "15px"
      }
    },
    "& .tab-pannel-wrapper": {
      "&.enable-scroll": {
        maxHeight: "660px",
        overflow: "auto",
        paddingTop: "0"
      }
    },
    "& .MuiTabs-indicator": {
      visibility: "hidden",
      opacity: "0",
      display: "none"
    },
    "& .MuiTabs-flexContainer": {
      "& button.MuiTab-textColorPrimary": {
        paddingBottom: "0 !important",
        marginBottom: "25px"
      }
    }
  },
});

export { DamageReportStyle };
