import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";

import { useStore } from "@store/store";
import { convertMinutesToHours } from "@utils/commonFunctions";
import HorizontalBarChart from "./horizontalBarChart";
import HorizontalBarChartStatus from "./horizontalBarChartStatus";
import GroupedBarChart from "./groupedBarChart";
import SpeedoMeterChart from "./speedometerChart";
import BarChart from "./barChart";
import { ChartBoxStyle } from "./style";

function ChartBox(props) {
  const classes = ChartBoxStyle();
  const [cargo, setCargo] = useState([]);
  const [awb, setAwb] = useState([]);
  const [durationHours, setDurationHours] = useState([]);
  const [waitingHours, setWaitingHours] = useState([]);
  const [jobStatus, setJobStatus] = useState([]);
  const [state] = useStore();

  useEffect(() => {
    // For cargo volume
    let cargoArr = [
      {
        labels: [],
        weight: [],
        quantity: [],
      },
    ];
    state?.dashboard?.dashboardData?.data?.cargoVolumeByCTO?.map((item) => {
      cargoArr[0].labels?.push(item?.ctoName);
      cargoArr[0].weight?.push(item?.weight);
      cargoArr[0].quantity?.push(item?.quantity);
      return true;
    });
    setCargo(cargoArr);
    // For job status

    const jobStatusArr = [
      {
        label: [],
        data: [],
        backgroundColor: [
          "#28cd84",
          "#fbbe3e",
          "#f12121",
          "#58bd45",
          "#c165e8",
          "#3a3939",
          "#0e99ff",
          "#939393",
          "#192d91",
          "#63d677",
        ],
      },
    ];

    for (var i in state?.dashboard?.dashboardData?.data?.jobStatus?.rows) {
      if (i === "notAssigned") jobStatusArr[0].label.push("Not Assigned");
      if (i === "assigned") jobStatusArr[0].label.push("Assigned");
      if (i === "changeDriver") jobStatusArr[0].label.push("Change Driver");
      if (i === "completed") jobStatusArr[0].label.push("Completed");
      if (i === "inTransit") jobStatusArr[0].label.push("In Transit");
      if (i === "loading") jobStatusArr[0].label.push("Loading");
      if (i === "lodgement") jobStatusArr[0].label.push("Lodgement");
      if (i === "rejected") jobStatusArr[0].label.push("Rejected");
      if (i === "reviewCompleted")
        jobStatusArr[0].label.push("Review Completed");
      if (i === "unloading") jobStatusArr[0].label.push("Unloading");
      jobStatusArr[0].data.push(
        state?.dashboard?.dashboardData?.data?.jobStatus?.rows[i]
      );
    }
    setJobStatus(jobStatusArr);
    // For Jobs whose AWB is not taken fully
    let awbArr = [
      {
        label: [],
        data: [],
      },
    ];
    state?.dashboard?.dashboardData?.data?.jobsAWBNotTakenFully?.map((item) => {
      awbArr[0].label?.push(item?.jobId);
      awbArr[0].data?.push(item?.awbRemainderQty);
      return true;
    });
    setAwb(awbArr);

    // For Job duration over 3 hours
    let hoursDurationArr = [
      {
        label: [],
        data: [],
        type: [],
      },
    ];
    state?.dashboard?.dashboardData?.data?.jobDurationOver3Hours?.rows?.map(
      (item) => {
        hoursDurationArr[0].label?.push(`${item.id}-${item.jobTypes?.name}`);
        hoursDurationArr[0].data?.push(
          parseInt(convertMinutesToHours(item.totalDuration))
        );
        hoursDurationArr[0].type?.push(
          item.ctos ? item?.ctos?.name : "consignment"
        );
        return true;
      }
    );
    setDurationHours(hoursDurationArr);

    // For Job Waiting Time over 2 hours
    let waitingHours = [
      {
        label: [],
        data: [],
        type: [],
      },
    ];
    state?.dashboard?.dashboardData?.data?.jobWaitingTimeOver2Hours?.rows?.map(
      (item) => {
        waitingHours[0].label?.push(`${item.id}-${item.jobTypes?.name}`);
        waitingHours[0].data?.push(
          parseInt(convertMinutesToHours(item.waitingTimeOver))
        );
        waitingHours[0].type?.push(
          item.ctos ? item?.ctos?.name : "consignment"
        );
        return true;
      }
    );
    setWaitingHours(waitingHours);
  }, [state?.dashboard]);

  return (
    <div className={classes.ChartBoxWrapper}>
      <div className="dashboard-details-wrapper">
        <div className="two-coloumn-block ">
          <div className="dashboard-details-inner side-content">
            <div className="inner-white-box">
              <div className="chart-title">
                <div className="title-text">
                  <Typography variant="h2">Cargo Volume by CTO</Typography>
                </div>
                {cargo && cargo[0]?.labels?.length > 0 && (
                  <div className="chart-info">
                    <div className="chart-info-inner">
                      {state?.common?.cargoTypeData?.map((item, index) => {
                        return (
                          <span
                            key={index}
                            className={`${
                              item.name === "ULD"
                                ? "lightblue-text"
                                : "light-red-text"
                            }`}
                            onClick={() =>
                              props.handleRedirection("CARGO_VOLUME", item.id)
                            }
                          >
                            {item.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {cargo && cargo[0]?.labels?.length > 0 ? (
                <div className="chart-wrapper">
                  <GroupedBarChart data={cargo && cargo[0]} />
                </div>
              ) : (
                <div className="data-text">
                  <Typography variant="h2"> No Data Available</Typography>
                </div>
              )}
            </div>
          </div>
          <div className="dashboard-details-inner jobStatusBar">
            <div className="inner-white-box">
              <div className="chart-title">
                <div className="title-text">
                  <Typography variant="h2">Job Status</Typography>
                </div>
              </div>
              {jobStatus &&
              jobStatus[0]?.data?.every((item) => item === 0) === true ? (
                <div className="data-text">
                  <Typography variant="h2"> No Data Available</Typography>
                </div>
              ) : (
                <div className="chart-wrapper">
                  <HorizontalBarChartStatus
                    data={jobStatus && jobStatus[0]}
                    handleRedirection={(val) =>
                      props.handleRedirection("JOBS_STATUS_REDIRECTION", val)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="two-coloumn-block">
          <div
            className="dashboard-details-inner"
            onClick={() => props.handleRedirection("JOBS_GRAPH_FILTER", 1)}
          >
            <div className="inner-white-box">
              <div className="chart-title">
                <div className="title-text">
                  <Typography variant="h2">
                    Job duration over 3 hours{" "}
                    <span>
                      (
                      {
                        state?.dashboard?.dashboardData?.data
                          ?.jobDurationOver3Hours?.count
                      }{" "}
                      Jobs)
                    </span>
                  </Typography>
                </div>
              </div>
              {durationHours && durationHours[0]?.data?.length > 0 ? (
                <div className="chart-wrapper">
                  <HorizontalBarChart
                    data={durationHours && durationHours[0]}
                  />
                </div>
              ) : (
                <div className="data-text-2">
                  <Typography variant="h2"> No Data Available</Typography>
                </div>
              )}
            </div>
          </div>
          <div
            className="dashboard-details-inner"
            onClick={() => props.handleRedirection("JOBS_GRAPH_FILTER", 2)}
          >
            <div className="inner-white-box">
              <div className="chart-title">
                <div className="title-text">
                  <Typography variant="h2">
                    Job Waiting Time over 2 hours{" "}
                    <span>
                      (
                      {
                        state?.dashboard?.dashboardData?.data
                          ?.jobWaitingTimeOver2Hours?.count
                      }{" "}
                      Jobs)
                    </span>
                  </Typography>
                </div>
              </div>
              {waitingHours && waitingHours[0]?.data?.length > 0 ? (
                <div className="chart-wrapper">
                  <HorizontalBarChart data={waitingHours && waitingHours[0]} />
                </div>
              ) : (
                <div className="data-text-2">
                  <Typography variant="h2"> No Data Available</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="two-coloumn-block">
          <div className="dashboard-details-inner">
            <div className="inner-white-box">
              <div className="chart-title">
                <div className="title-text">
                  <Typography variant="h2">
                    Average Loading/Unloading Duration
                  </Typography>
                </div>
              </div>
              <div className="chart-wrapper">
                <div className="chart-element">
                  <SpeedoMeterChart
                    className="loading-time"
                    needleValue={
                      state?.dashboard?.dashboardData?.data?.averageDuration
                        ?.averageLoadingTime
                        ? state?.dashboard?.dashboardData?.data?.averageDuration
                            ?.averageLoadingTime > 120
                          ? 120
                          : parseInt(
                              state?.dashboard?.dashboardData?.data
                                ?.averageDuration?.averageLoadingTime
                            )
                        : 0
                    }
                  />
                  <div className="duration-text">
                    <span className="minutes">
                      {state?.dashboard?.dashboardData?.data?.averageDuration
                        ?.averageLoadingTime
                        ? parseInt(
                            state?.dashboard?.dashboardData?.data
                              ?.averageDuration?.averageLoadingTime
                          )
                        : 0}{" "}
                      Mins
                    </span>
                    <span className="time-text">Loading Time</span>
                  </div>
                </div>
                <div className="chart-element">
                  <SpeedoMeterChart
                    className="unloading-time"
                    needleValue={
                      state?.dashboard?.dashboardData?.data?.averageDuration
                        ?.averageUnloadingTime !== null
                        ? state?.dashboard?.dashboardData?.data?.averageDuration
                            ?.averageUnloadingTime > 120
                          ? 120
                          : parseInt(
                              state?.dashboard?.dashboardData?.data
                                ?.averageDuration?.averageUnloadingTime
                            )
                        : 0
                    }
                  />
                  <div className="duration-text">
                    <span className="minutes">
                      {state?.dashboard?.dashboardData?.data?.averageDuration
                        ?.averageUnloadingTime
                        ? parseInt(
                            state?.dashboard?.dashboardData?.data
                              ?.averageDuration?.averageUnloadingTime
                          )
                        : 0}{" "}
                      Mins
                    </span>
                    <span className="time-text">Unloading Time</span>
                  </div>
                </div>
              </div>
              <div className="chart-footer-content">
                <div className="chart-info">
                  <div className="chart-info-inner">
                    <span className="dark-green-text">&lt;30</span>
                    <span className="green-text">30-45</span>
                    <span className="light-green-text">45-60</span>
                    <span className="yellow-text">60-90</span>
                    <span className="light-red-text">90-120</span>
                    <span className="dark-red-text">&gt;120</span>
                  </div>
                </div>
                <p>(In Minutes)</p>
              </div>
            </div>
          </div>

          <div
            className="dashboard-details-inner awb-not-taken-fully"
            onClick={() => props.handleRedirection("JOBS_GRAPH_FILTER", 3)}
          >
            <div className="inner-white-box">
              <label className="label-text">
                Jobs whose AWB is not taken fully
              </label>
              {awb && awb[0]?.data?.length > 0 ? (
                <BarChart
                  labels={awb && awb[0]?.label}
                  data={awb && awb[0]?.data}
                  backgroundColor="#F5C32E"
                  labelString="JOB ID"
                  beginAtZero={true}
                />
              ) : (
                <div className="data-text">
                  <Typography variant="h2"> No Data Available</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ChartBox;
