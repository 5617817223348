import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useStore } from "@store/store";

import closeIcon from "@assets/images/close.svg";
import Detail from "./detail";
import DateFields from "./dateFields";
import DangerousFields from "./dangerousFields";
import { AddAirWayBillStyle } from "./style";

function AddAirWayBill(props) {
  const classes = AddAirWayBillStyle();
  const [scroll] = useState("body");
  const [state] = useStore();

  return (
    <div className={classes.AddAirWayBillWrapper}>
      {["Created", "Acknowledged"].includes(
        props.onlineData?.onlineRequestStatuses?.name
      ) &&
        props?.onlineData?.jobTypes?.name !== "Empty" && (
          <Button
            className="orange-btn primary-btn"
            color="inherit"
            disableElevation
            underlinenone="true"
            onClick={props.handleClickOpen}
          >
            + Add air waybill
          </Button>
        )}
      <Dialog open={props.open} className={classes.customModal} scroll={scroll}>
        <div className="close-modal">
          <img src={closeIcon} alt="Close" onClick={props.handleClose} />
        </div>
        <form
          noValidate
          autoComplete="off"
          className={classes.customForm}
          onSubmit={props.formik.handleSubmit}
        >
          {props.error && <Alert severity="error">{props.error}</Alert>}
          <DialogTitle>
            {props.isEdit
              ? ["Created", "Acknowledged"].includes(
                  props.onlineData?.onlineRequestStatuses?.name
                )
                ? "Edit"
                : "View"
              : "Add"}{" "}
            air waybill
          </DialogTitle>
          <DialogContent>
            <Detail formik={props.formik} />
            <DateFields formik={props.formik} />
            <DangerousFields formik={props.formik} />
          </DialogContent>
          <DialogActions className="bottom-button-block">
            <Button
              className="primary-btn gray-border-btn"
              color="inherit"
              disableElevation
              underlinenone="true"
              onClick={props.handleClose}
            >
              CANCEL
            </Button>
            {["Created", "Acknowledged"].includes(
              props.onlineData?.onlineRequestStatuses?.name
            ) && (
              <Button
                className="orange-btn primary-btn"
                color="inherit"
                disableElevation
                underlinenone="true"
                type="submit"
                disabled={
                  state?.onlineRequest?.addAirWayBillLoad ||
                  state.onlineRequest.editingAWB
                }
              >
                {state?.onlineRequest?.addAirWayBillLoad ||
                state.onlineRequest.editingAWB ? (
                  <CircularProgress color="inherit" />
                ) : props.isEdit ? (
                  "Edit"
                ) : (
                  "Add"
                )}{" "}
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}

export default AddAirWayBill;
