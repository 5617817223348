import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Checkbox,
  ListItemText,
  InputLabel,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { useStore } from "@store/store";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIcon from "@assets/images/checked-icon.svg";
import {
  setChildId,
  setAccess,
  getAccess,
  getToken,
} from "@utils/commonFunctions";
import {
  FETCH_CHILD_ACCOUNTS,
  FETCH_CHILD_ACCOUNTS_SUCCESS,
  FETCH_CHILD_ACCOUNTS_FAILURE,
} from "@utils/actionTypes";
import { bannerStyle } from "@utils/commonStyles";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { routes } from "@utils/constant";
import Header from "@components/header";
import Footer from "@components/footer";
import { getApi } from "@services/axios";

function WelcomePage() {
  const classes = bannerStyle();
  const materilClasses = materialCommonStyles();
  const [state, dispatch] = useStore();

  const history = useHistory();
  const [division, setDivision] = useState([]);

  const handleChange = (event) => {
    setDivision(event.target.value);
  };

  // const menuData = [
  //   {
  //     value: "wymap_import",
  //     label: "Wymap Import",
  //   },
  //   {
  //     value: "wymap_export",
  //     label: "Wymap Export",
  //   },
  // ];

  const menuData = state?.common?.childAccountsData?.rows?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  useEffect(() => {
    if (getToken() && getAccess()) {
      history.push(routes.dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // API calling to get child accounts
  const getChildAccount = () => {
    dispatch({ type: FETCH_CHILD_ACCOUNTS });
    getApi("childAccounts", null, false)
      .then((response) => {
        dispatch({
          type: FETCH_CHILD_ACCOUNTS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: FETCH_CHILD_ACCOUNTS_FAILURE,
          payload: error.response.data,
        });
      });
  };
  useEffect(() => {
    getChildAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setAccess();
    history.push(routes.dashboard);
    setChildId(division);
  };

  return (
    <>
      <Header />
      <div className={classes.commonBannerWrapper}>
        <div className="commonBanner">
          <div className="white-box-wrapper">
            <div className="white-box">
              <div className="title-wrapper">
                <Typography variant="h1">Welcome to Wymap</Typography>
                <p>
                  Please select one type or multiple type or all job to get the
                  filtered information.
                </p>
              </div>
              <form
                noValidate
                autoComplete="off"
                className="custom-form"
                onSubmit={handleSubmit}
              >
                <div className="form-row">
                  <div className="form-gourp">
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="division">Division</InputLabel>
                      <Select
                        labelId="division"
                        value={division}
                        onChange={handleChange}
                        displayEmpty
                        label="Division"
                        className={materilClasses.customSelect}
                        multiple
                        MenuProps={{
                          classes: { paper: materilClasses.customSelect },
                        }}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <>Select Division</>;
                          }

                          return menuData
                            ?.filter((m) => selected.includes(m.value))
                            .map((m) => m.label)
                            .join(",");
                        }}
                        IconComponent={() => <ExpandMore />}
                      >
                        <MenuItem value="" disabled>
                          Select Division
                        </MenuItem>
                        {menuData?.map((item, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={item.value}
                              className="custom-checkbox custom-Multicheckbox"
                            >
                              <Checkbox
                                checked={division.indexOf(item.value) > -1}
                                icon={
                                  <img src={uncheckedIcon} alt="CheckBox" />
                                }
                                checkedIcon={
                                  <img src={checkedIcon} alt="CheckBox" />
                                }
                              />
                              <ListItemText primary={item.label} />
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="form-row btn-wrapper">
                  <Button
                    className="orange-btn"
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    // disabled={division.length === 0}
                  >
                    Proceed
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WelcomePage;
