import React from "react";

import Popup from "../popup";
import { AddNewULDStyle } from "./style";

function AddNewULD(props) {
  const classes = AddNewULDStyle();

  return (
    <div className={classes.AddNewItemWrapper}>
      <Popup
        formik={props.formik}
        open={props.open}
        handleClose={props.handleClose}
        isEdit={props.isEdit}
      />
    </div>
  );
}
export default AddNewULD;
