import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import Detail from "./detail";
import Location from "./location";
import { requestFormStyle } from "./style";

function RequestForm(props) {
  const classes = requestFormStyle();
  const [scroll] = useState("body");
  const [state] = useStore();
  return (
    <Dialog
      open={props.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.customModal}
      scroll={scroll}
    >
      <div className="close-modal">
        <img src={closeIcon} alt="Close" onClick={props.handleClose} />
      </div>
      {props.error && <Alert severity="error">{props.error}</Alert>}
      <DialogTitle id="alert-dialog-title">
        {props.isEdit ? "Edit" : "Make"} a Request
      </DialogTitle>
      <DialogContent>
        <form
          noValidate
          autoComplete="off"
          className="custom-form"
          onSubmit={props.formik.handleSubmit}
        >
          <Detail
            formik={props.formik}
            isEdit={props.isEdit}
            handleDialogOnChange={props.handleDialogOnChange}
            handleChip={props.handleChip}
          />
          <Location
            formik={props.formik}
            handleChange={props.handleChange}
            handleSelect={props.handleSelect}
          />
          <div className="bottom-button-block">
            <Button
              className="primary-btn gray-border-btn btn"
              color="inherit"
              disableElevation
              onClick={props.handleClose}
            >
              CANCEL
            </Button>
            <Button
              className="orange-btn btn"
              color="inherit"
              disableElevation
              type="submit"
              disabled={
                state.onlineRequest?.creatingOnlineRequest ||
                state?.onlineRequest?.updatingOnlineRequest
              }
            >
              {state.onlineRequest?.creatingOnlineRequest ||
              state?.onlineRequest?.updatingOnlineRequest ? (
                <CircularProgress color="inherit" />
              ) : props.isEdit ? (
                "Edit"
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default RequestForm;
