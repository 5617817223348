import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const SideBarStyle = makeStyles((theme) => ({
	drawerWrapper: {
		position: "absolute",
		right: "50px",
		height: "100%",
		"& .drawer-wrapper": {
			width: "420px",
			height: "100%",
			padding: "19px 30px 30px",
			overflowY: "auto",
			"& .circular-progress": {
				display: "flex",
				justifyContent: "center",
				marginTop: "20px",
			},
			"@media(max-width:767px)": {
				padding: "20px 15px",
				width: "350px",
			},
			"@media(max-width:480px)": {
				width: "300px",
			},
			"@media(max-width:374px)": {
				width: "280px",
			},
			"& .uld-list-wrapper": {
				"& .uld-list-item-red": {
					backgroundColor: colors.orange,
					color: colors.black,
					padding: "11px 5px 11px 15px",
					display: "block",
					alignItems: "center",
					justifyContent: "space-between",
					boxShadow: "0 3px 8px 0 rgb(97 97 97 / 8%)",
					borderRadius: "6px",
					border: "1px solid black",
					marginTop: "15px",
					"& + .uld-list-item": {
						marginTop: "15px",
					},
					"& .uld-id": {
						fontSize: "18px",
						fontWeight: "600",
						color: colors.black,
						paddingRight: "10px",
					},
				},
				"& .uld-list-item": {
					backgroundColor: colors.primary,
					color: colors.white,
					padding: "11px 5px 11px 15px",
					display: "block",
					alignItems: "center",
					justifyContent: "space-between",
					boxShadow: "0 3px 8px 0 rgb(97 97 97 / 8%)",
					borderRadius: "6px",
					border: "1px solid black",
					"& + .uld-list-item": {
						marginTop: "15px",
					},
					"& .uld-id": {
						fontSize: "18px",
						fontWeight: "600",
						paddingRight: "10px",
						color: colors.white,
					},
				},
			},
		},
		"& ::-webkit-scrollbar": {
			width: 0,
			background: "transparent",
		},
		"& ::-webkit-scrollbar-thumb": {
			background: "#fff",
		},
	},
}));

export { SideBarStyle };
