import React, { useState, useEffect } from "react";
import { Typography, TextField } from "@material-ui/core";
import moment from "moment";

import { useDebouncedEffect } from "@hooks/debounceEffect";
import { useStore } from "@store/store";
import Loader from "@components/loader";
import Header from "@components/header";
import {
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
} from "@utils/actionTypes";
import { getFilter, setFilter, removeFilter } from "@utils/commonFunctions";
import { rowsPerPageVal } from "@utils/constant";
import { getApi } from "@services/axios";
import Filter from "./filter";
import TableListing from "./tableListing";
import { invoiceStyle } from "./style";

function InvoiceView() {
  const classes = invoiceStyle();
  const filter = getFilter("invoiceFilter", true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageVal);
  const [getState, setState] = useState({
    right: false,
  });
  const [period, setPeriod] = useState(filter?.period || "");
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [updateFilter, setUpdateFilter] = useState({});
  const [filterData, setFilterData] = useState({
    customer: filter?.customer || "",
    startDate: filter?.startDate || "",
    endDate: filter?.endDate || "",
  });
  const [customDate, setCustomDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [state, dispatch] = useStore();

  // API calling to get invoice
  const getInvoices = () => {
    const params = {
      page: page + 1,
      size: rowsPerPage,
      ...(!!search ? { search } : {}),
      filter: {
        ...(!!filterData.customer
          ? { childCustomer: filterData.customer }
          : {}),
        ...(!!filterData.startDate
          ? { startDate: filterData.startDate }
          : !!customDate.startDate
          ? { startDate: filterData.startDate }
          : {}),
        ...(!!filterData.endDate
          ? { endDate: filterData.endDate }
          : !!customDate.endDate
          ? { endDate: filterData.endDate }
          : {}),
      },
    };
    dispatch({ type: FETCH_INVOICES });
    getApi("invoices", { params }, true)
      .then((response) => {
        dispatch({
          type: FETCH_INVOICES_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_INVOICES_FAILURE, payload: error });
      });
  };

  // To open drawer of filter
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...getState, [anchor]: open });
  };

  useEffect(() => {
    getInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, updateFilter]);

  useEffect(() => {
    return () => {
      removeFilter("invoiceFilter");
    };
  }, []);

  useDebouncedEffect(() => getInvoices(), 1000, [search]);
  const handleSearch = (event) => {
    setSearch(event.target.value.trimStart());
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterData({
      ...filterData,
      [name]: value,
    });
  };
  const handlePeriodChange = (event, type) => {
    const { value } = event.target;
    setPeriod(value);
    if (value === 7 || type === true) {
      setOpen(true);
    } else {
      const newDate = new Date();
      if (value === "") {
        setFilterData({
          ...filterData,
          startDate: null,
          endDate: null,
        });
        setCustomDate({
          startDate: null,
          endDate: null,
        });
      } else if (value === 1) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 2) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(1, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).subtract(1, "day").format("YYYY-MM-DD"),
        });
      } else if (value === 3) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(3, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 4) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(7, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 5) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(14, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      } else if (value === 6) {
        setFilterData({
          ...filterData,
          startDate: moment(newDate).subtract(30, "day").format("YYYY-MM-DD"),
          endDate: moment(newDate).format("YYYY-MM-DD"),
        });
      }
    }
  };
  const handleClose = () => {
    setPeriod("");
    setOpen(false);
    setCustomDate({
      startDate: null,
      endDate: null,
    });
  };

  const handleCustomChange = (event, type) => {
    if (type === "startDate" || type === "endDate") {
      setCustomDate({
        ...customDate,
        [type]: moment(event).format("YYYY-MM-DD"),
      });
    } else {
      const { name, value } = event.target;
      setCustomDate({
        ...customDate,
        [name]: value,
      });
    }
  };
  const handleCustomDateSubmit = () => {
    setOpen(false);
    setShow(true);
    setFilterData({
      ...filterData,
      startDate: customDate.startDate,
      endDate: customDate.endDate,
    });
    setPeriod(7);
  };
  const handleSubmit = (anchor) => {
    setUpdateFilter(filterData);
    setState({ ...getState, [anchor]: false });
    setFilter("invoiceFilter", { ...filterData, period }, true);
  };
  const handleReset = (anchor) => {
    removeFilter("invoiceFilter");
    setState({ ...getState, [anchor]: false });
    setShow(false);
    setPeriod("");
    setFilterData({
      customer: "",
      startDate: null,
      endDate: null,
    });
    setUpdateFilter({
      customer: "",
      startDate: null,
      endDate: null,
    });
    setCustomDate({
      startDate: null,
      endDate: null,
    });
  };
  return (
    <>
      <Header />
      <div className={classes.invoiceWrapper}>
        <Loader loading={state?.invoice?.laodingInvoices} />
        <div className="wrapper">
          <div className="request-form-section">
            <div className="filter-search-title-strip">
              <Typography variant="h1">Invoice</Typography>
              <div className="filter-search-wrapper">
                <div className="inner-col">
                  <div className="form-gourp">
                    <TextField
                      id="search-request"
                      placeholder="Search Invoice Number"
                      variant="outlined"
                      type="search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="inner-col">
                  <div className="form-gourp">
                    <Filter
                      toggleDrawer={toggleDrawer}
                      getState={getState}
                      handleFilter={handleFilter}
                      filterData={filterData}
                      handleSubmit={handleSubmit}
                      handlePeriodChange={handlePeriodChange}
                      handleClose={handleClose}
                      period={period}
                      open={open}
                      show={show}
                      handleReset={handleReset}
                      customDate={customDate}
                      handleCustomChange={handleCustomChange}
                      handleCustomDateSubmit={handleCustomDateSubmit}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableListing
              page={page}
              handleChangePage={handleChangePage}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default InvoiceView;
