import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const childAccountStyle = makeStyles((theme) => ({
  childAccountWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
    "& .account-data-table": {
      borderRadius: "6px",
      border: "1px solid #e3e3e3",
      borderCollapse: "separate",
      "& thead": {
        "& th": {
          fontSize: "16px",
          fontWeight: "500",
          opacity: "0.7",
          borderColor: colors.grayborder,
          padding: "25px 31px",
          "@media(max-width:767px)": {
            padding: "15px",
          },
        },
      },
      "& tbody": {
        " & td": {
          fontWeight: "500",
          border: "0",
          padding: "29px 31px",
          "&:first-child": {
            textTransform: "uppercase",
          },
          "@media(max-width:767px)": {
            padding: "15px",
          },
        },
      },
    },
  },
}));

export { childAccountStyle };
