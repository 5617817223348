import React from "react";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";

import { useStore } from "@store/store";
import Pagination from "@components/pagination";
import DiscussionThread from "@components/discussion-thread-main";
import { requestHeading, routes } from "@utils/constant";
import { requestStatusColors } from "@utils/commonFunctions";
import { tableStyles } from "./style";

function TableListing(props) {
  const classes = tableStyles();
  const [state] = useStore();

  return (
    <div className={classes.tableWrapper}>
      <TableContainer component={Paper} className={classes.customTable}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {requestHeading.map((item, index) => {
                return (
                  <TableCell key={index} className={item.className}>
                    {item.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {state?.onlineRequest?.onlineRequestData?.count === 0 ? (
              <TableRow className="no-data">
                <TableCell colSpan={7}>
                  <span>No Data Found</span>
                </TableCell>
              </TableRow>
            ) : (
              state?.onlineRequest?.onlineRequestData?.rows?.map(
                (item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          to={{
                            pathname: `${routes.onlinRequestDetail}/${item.id}`,
                            state: item,
                          }}
                        >
                          {item.id || "-"}
                        </Link>
                      </TableCell>
                      <TableCell>{item.customers?.name || "-"}</TableCell>
                      <TableCell>{item.jobTypes?.name || "-"}</TableCell>
                      <TableCell>{item.cargoTypes?.name || "-"}</TableCell>
                      <TableCell>{item.ctos?.name || "-"}</TableCell>
                      <TableCell
                        className={`request-status ${requestStatusColors(
                          item.onlineRequestStatuses?.name
                        )}`}
                      >
                        <span>{item.onlineRequestStatuses?.name || "-"}</span>
                      </TableCell>
                      <TableCell>
                        {item.jobStatuses
                          ? item.jobStatuses.name === "Review Completed"
                            ? "Completed"
                            : item.jobStatuses.name === "Change Driver"
                            ? "In Transit"
                            : item.jobStatuses.name
                          : "-"}
                      </TableCell>
                      <TableCell>{item.referenceNo || "-"}</TableCell>

                      <TableCell className="chat-column">
                        <DiscussionThread
                          sendMessage={props.sendMessage}
                          rowId={item.id}
                          state={props.state}
                          handleMessage={props.handleMessage}
                          newMessage={props.newMessage}
                          messages={
                            state?.onlineRequest?.onlineRequestChats?.rows
                          }
                          messagePage={props.messagePage}
                          setMessagePage={props.setMessagePage}
                          handleSearchedMessage={props.handleSearchedMessage}
                          searchMessage={props.searchMessage}
                          setSearchMessage={props.setSearchMessage}
                          messageSent={props.messageSent}
                          setMessageSent={props.setMessageSent}
                          openChat={props.openChat}
                          uploadFile={props.uploadFile}
                          image={props.image}
                          setImage={props.setImage}
                          chatType={props.chatType}
                          error={props.error}
                          setOpenChat={props.setOpenChat}
                          setSenderId={props.setSenderId}
                          loading={
                            state?.onlineRequest?.savingChat ||
                            state?.common?.imageUploading
                          }
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {state?.onlineRequest?.onlineRequestData?.count ? (
        <div className="pagination-wrapper">
          <Pagination
            count={state?.onlineRequest?.onlineRequestData?.count}
            page={props.page}
            handleChangePage={props.handleChangePage}
            rowsPerPage={props.rowsPerPage}
            handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default TableListing;
