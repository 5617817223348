import React, { useEffect } from "react";
import {
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  SwipeableDrawer,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { useStore } from "@store/store";
import filterIcon from "@assets/images/filter-icon.svg";
import closeIcon from "@assets/images/close.svg";
import {
  FETCH_CHILD_ACCOUNTS,
  FETCH_CHILD_ACCOUNTS_SUCCESS,
  FETCH_CHILD_ACCOUNTS_FAILURE,
} from "@utils/actionTypes";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { getCustomFormToDate } from "@utils/commonFunctions";
import { getApi } from "@services/axios";
import Popup from "./popup";
import { RequestFilterStyle } from "./style";

function OnlineRequestFilter(props) {
  const [state, dispatch] = useStore();
  const classes = RequestFilterStyle();
  const materilClasses = materialCommonStyles();

  const getChildAccount = () => {
    if (state?.common?.childAccountsData === null) {
      dispatch({ type: FETCH_CHILD_ACCOUNTS });
      getApi("childAccounts", null, false)
        .then((response) => {
          dispatch({
            type: FETCH_CHILD_ACCOUNTS_SUCCESS,
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: FETCH_CHILD_ACCOUNTS_FAILURE,
            payload: error.response.data,
          });
        });
    }
  };
  useEffect(() => {
    getChildAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.RequestFilterWrapper}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="primary-btn blue-btn lg"
            variant="contained"
            color="primary"
            disableElevation
            onClick={props.toggleDrawer(anchor, true)}
          >
            <img src={filterIcon} alt="Filter" />
            Filter
          </Button>
          <SwipeableDrawer
            className={classes.drawerWrapper}
            anchor={anchor}
            open={props.getState[anchor]}
            onClose={props.toggleDrawer(anchor, false)}
            onOpen={props.toggleDrawer(anchor, true)}
          >
            <div className="drawer-wrapper">
              <div className="filter-form">
                <form noValidate autoComplete="off" className="custom-form">
                  <div className="form-row">
                    <div className="filter-title-block form-group">
                      <Typography variant="h2">Filter</Typography>
                      <img
                        src={closeIcon}
                        alt="Close"
                        onClick={props.toggleDrawer(anchor, false)}
                      />
                    </div>

                    <div className="form-gourp">
                      <div className="label-wrapper">
                        <label className="label-text">Child customer</label>
                      </div>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          value={props.filterData?.customer}
                          name="customer"
                          onChange={(e) => props.handleFilter(e)}
                          displayEmpty
                          className={materilClasses.customSelect}
                          MenuProps={{
                            classes: { paper: materilClasses.customSelect },
                          }}
                          IconComponent={() => <ExpandMore />}
                        >
                          <MenuItem value={""}>Select Child Customer</MenuItem>
                          {state?.common?.loadingChildAccounts ? (
                            <MenuItem>Loading...</MenuItem>
                          ) : (
                            state?.common?.childAccountsData?.rows?.map(
                              (item, index) => {
                                return (
                                  <MenuItem key={index} value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              }
                            )
                          )}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="form-gourp">
                      <div className="label-wrapper">
                        <label className="label-text">Reporting period</label>
                      </div>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          value={props.period}
                          onChange={props.handlePeriodChange}
                          placeholder="06/12/2021"
                          displayEmpty
                          className={materilClasses.customSelect}
                          MenuProps={{
                            classes: { paper: materilClasses.customSelect },
                          }}
                          IconComponent={() => <ExpandMore />}
                        >
                          <MenuItem value={""}>Select Period</MenuItem>
                          <MenuItem value={1}>Today</MenuItem>
                          <MenuItem value={2}>Yesterday</MenuItem>
                          <MenuItem value={3}>Last 3 days</MenuItem>
                          <MenuItem value={4}>Last 7 days</MenuItem>
                          <MenuItem value={5}>Last fortnight</MenuItem>
                          <MenuItem value={6}>Last 30 days</MenuItem>
                          <MenuItem
                            value={7}
                            onClick={(e) => props.handlePeriodChange(e, true)}
                          >
                            {props.show &&
                            props.customDate.startDate &&
                            props.customDate.endDate
                              ? getCustomFormToDate(
                                  props.customDate.startDate,
                                  props.customDate.endDate
                                )
                              : "Custom Range"}
                          </MenuItem>
                        </Select>
                        <Popup
                          open={props.open}
                          handleSubmit={props.handleCustomDateSubmit}
                          handleClose={props.handleClose}
                          data={props.customDate}
                          handleChange={(e, type) =>
                            props.handleCustomChange(e, type)
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="button-wrapper">
              <div className="button-wrapper-inner">
                <Button
                  className="primary-btn gray-border-btn btn"
                  color="inherit"
                  disableElevation
                  onClick={() => props.handleReset(anchor)}
                >
                  RESET
                </Button>
                <Button
                  className="orange-btn primary-btn btn"
                  color="inherit"
                  disableElevation
                  onClick={() => props.handleSubmit(anchor)}
                >
                  APPLY
                </Button>
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default OnlineRequestFilter;
