import axios from "axios";

import { apiUrl } from "@utils/constant";
import {
  getToken,
  removeToken,
  removeAccess,
  removeChildId,
} from "@utils/commonFunctions";

const API = axios.create({
  baseURL: apiUrl,
});

API.interceptors.request.use(
  function (config) {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.href = "/login";
        removeToken();
        removeAccess();
        removeChildId();
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

const getApi = (slug, par, needChild) => {
  let params;
  if (needChild === true) {
    if (localStorage.getItem("childs_id")) {
      params = {
        params: {
          ...par.params,
          filter: {
            ...par.params.filter,
            childCustomers: localStorage
              .getItem("childs_id")
              ?.split(",")
              .map((item) => {
                if (item === "") {
                } else {
                  return parseInt(item);
                }
                return true;
              }),
          },
        },
      };
    } else {
      params = par;
    }
  } else {
    params = par;
  }
  return API.get(slug, params);
};

const postApi = (slug, values) => {
  return API.post(slug, values);
};

const putApi = (slug, values) => {
  return API.put(slug, values);
};

const deleteApi = (slug) => {
  return API.delete(slug);
};

export { putApi, getApi, postApi, deleteApi };
