import { makeStyles } from "@material-ui/core/styles";

const MapStyle = makeStyles({
	MapWrapper: {
		"& .MuiBackdrop-root": {
			color: " #fff",
			zIndex: "1201",
		},
		paddingBottom: "30px",
		"& .map-block": {
			position: "relative",
			height: "920px",
			"@media (max-width:1366px)": {
				height: "750px",
			},
			"& .map": {
				position: "absolute !important",
				"& .gm-style": {
					"& .gm-style-iw-c": {
						padding: "0 !important",
						maxHeight: "initial !important",
						maxWidth: "100% !important",
						overflow: "visible",
					},
					"& .gm-style-iw-d": {
						// overflowY: 'auto !important',
						overflow: "hidden !important",
						maxHeight: "100% !important",
					},
					"& button.gm-ui-hover-effect": {
						height: "35px !important",
						width: "35px !important",
						top: "-10px !important",
						right: "-10px !important",
						backgroundColor: "#fff !important",
						borderRadius: "50%",
						"box-shadow": "0px 0px 5px 0px rgb(0 0 0 / 10%)",
						display: "flex !important",
						alignItems: "center",
						justifyContent: "center",
						opacity: "1",
						"& img": {
							height: "22px !important",
							width: "22px !important",
						},
					},
				},
			},
		},
		"& .filter-wrapper": {
			display: "block",
			"& .filter-form-row": {
				alignItems: "center",
				flexWrap: "wrap",
				"& .form-gourp": {
					flex: "0 0 15.92%",
					maxWidth: "15.92%",
					"@media (max-width: 1600px)": {
						maxWidth: "24.28%",
						"@media (max-width: 1359px)": {
							flex: "0 0 100%",
							maxWidth: "100%",
						},
					},
					"&.filter-title-block": {
						"@media (max-width: 1359px)": {
							order: "-1",
						},
					},
				},
				"& .reset-column": {
					display: "block",
					"&.has-bottom-align": {
						alignSelf: "center",
						marginLeft: "auto",
						"@media (max-width: 1600px)": {
							marginLeft: "0",
						},
					},
					"@media (max-width: 1988px)": {
						padding: "10px",
					},
					"@media (max-width: 1299px)": {
						display: "none",
					},
				},
			},
		},
	},
});

export { MapStyle };
