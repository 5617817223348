import React from "react";
import {
  FormControlLabel,
  Zoom,
  Tooltip as HtmlTooltip,
  Checkbox,
  TextField,
  FormControl,
  Button,
} from "@material-ui/core";

import infoIcon from "@assets/images/information-icon.svg";
import uncheckedIcon from "@assets/images/uncheck-icon.svg";
import checkedIconOrange from "@assets/images/cheked-icon-orange.svg";
import { materialCommonStyles } from "@utils/materialCommonStyles";
import { allowOnlyNumbers, allowOnlyFloat } from "@utils/commonFunctions";
import { AddConsignmentStyle } from "../style";

function Comment(props) {
  const classes = AddConsignmentStyle();
  const materilClasses = materialCommonStyles();

  return (
    <>
      {props.formik.values.viewPhoto !== "" && (
        <div className="form-row ">
          <div className="form-group full-width">
            <FormControl variant="outlined" className={classes.formControl}>
              <label className="label-text" htmlFor="bill-number">
                View Uploaded Photo
              </label>
              <Button
                className="primary-btn gray-border-btn"
                color="inherit"
                disableElevation
                underlinenone="true"
                onClick={() => window.open(props.formik.values.viewPhoto)}
              >
                View
              </Button>
            </FormControl>
          </div>
        </div>
      )}
      <div className="form-row">
        <div className="form-group full-width-column checkbox-wrapper">
          <FormControlLabel
            className="custom-checkbox"
            control={
              <Checkbox
                icon={<img src={uncheckedIcon} alt="CheckBox" />}
                checkedIcon={<img src={checkedIconOrange} alt="CheckBox" />}
                onChange={props.formik.handleChange}
                value={props.formik?.values?.dangerousGood}
                checked={props.formik?.values?.dangerousGood}
                name="dangerousGood"
                id="dangerousGood"
              />
            }
            label="Dangerous Goods"
          />

          <HtmlTooltip
            TransitionComponent={Zoom}
            title={
              <>
                <p>
                  DG Lic required if DG is in a receptable with a capacity of
                  more than 500 liters or 500 kg. IBC's up to 3000 liters is OK
                  provided each are 500 kg.
                </p>
              </>
            }
            interactive
            arrow
            placement="right"
            classes={{ tooltip: materilClasses.modalTooltip }}
            enterTouchDelay={1}
          >
            <span className="info-icon">
              {" "}
              <img src={infoIcon} alt="Info" />
            </span>
          </HtmlTooltip>
        </div>
      </div>

      {props.formik.values.dangerousGood && (
        <>
          <div className="form-row">
            <div className="form-group">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text">DG Classification</label>
                <TextField
                  id="dgClassification"
                  placeholder="DG Classification"
                  variant="outlined"
                  name="dgClassification"
                  type="text"
                  onChange={props.formik.handleChange}
                  value={props.formik.values.dgClassification}
                  error={
                    props.formik.touched.dgClassification &&
                    Boolean(props.formik.errors.dgClassification)
                  }
                  helperText={
                    props.formik.touched.dgClassification &&
                    props.formik.errors.dgClassification
                  }
                />
              </FormControl>
            </div>
            <div className="form-group">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text">UN Number</label>
                <TextField
                  id="unNumber"
                  placeholder="UN Number"
                  variant="outlined"
                  type="text"
                  name="unNumber"
                  onKeyPress={allowOnlyNumbers}
                  onChange={props.formik.handleChange}
                  value={props.formik.values.unNumber}
                  error={
                    props.formik.touched.unNumber &&
                    Boolean(props.formik.errors.unNumber)
                  }
                  helperText={
                    props.formik.touched.unNumber &&
                    props.formik.errors.unNumber
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group two-column">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text">
                  Total weight or volume being carried per vehicle
                </label>
                <TextField
                  id="volume"
                  placeholder="Total weight or volume being carried per vehicle"
                  variant="outlined"
                  type="text"
                  name="volume"
                  onKeyPress={allowOnlyFloat}
                  onChange={props.formik.handleChange}
                  value={props.formik.values.volume}
                  error={
                    props.formik.touched.volume &&
                    Boolean(props.formik.errors.volume)
                  }
                  helperText={
                    props.formik.touched.volume && props.formik.errors.volume
                  }
                />
              </FormControl>
            </div>
            <div className="form-group two-column">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text">
                  Individual(inner) packaging weight or volume
                </label>
                <TextField
                  id="indVolume"
                  placeholder="Individual(inner) packaging weight or volume"
                  variant="outlined"
                  type="text"
                  name="indVolume"
                  onKeyPress={allowOnlyFloat}
                  onChange={props.formik.handleChange}
                  value={props.formik.values.indVolume}
                  error={
                    props.formik.touched.indVolume &&
                    Boolean(props.formik.errors.indVolume)
                  }
                  helperText={
                    props.formik.touched.indVolume &&
                    props.formik.errors.indVolume
                  }
                />
              </FormControl>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group full-width-column">
              <FormControl variant="outlined" className={classes.formControl}>
                <label className="label-text">
                  Will the driver be supplied with the paperwork and SDS at
                  loading or will that be emailed to us when confirming the
                  booking and we will provide to the driver?
                </label>
                <TextField
                  id="rePaperWork"
                  placeholder="Yes/No"
                  variant="outlined"
                  type="text"
                  name="rePaperWork"
                  onChange={props.formik.handleChange}
                  value={props.formik.values.rePaperWork}
                  error={
                    props.formik.touched.rePaperWork &&
                    Boolean(props.formik.errors.rePaperWork)
                  }
                  helperText={
                    props.formik.touched.rePaperWork &&
                    props.formik.errors.rePaperWork
                  }
                />
              </FormControl>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Comment;
