import {
  FETCH_TRUCKS,
  FETCH_TRUCKS_SUCCESS,
  FETCH_TRUCKS_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingTrucks: false,
  trucksData:null
};

const truckReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_TRUCKS:
      return { ...state, loadingTrucks: true };
    case FETCH_TRUCKS_SUCCESS:
      return { ...state, trucksData: action.payload, loadingTrucks: false };
    case FETCH_TRUCKS_FAILURE:
      return { ...state, trucksData: action.payload, loadingTrucks: false };

    default:
      return state;
  }
};
export default truckReducer;
