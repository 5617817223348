import React from "react";
import { Button } from "@material-ui/core";

import { GenerateTokenStyle } from "./style";
import Popup from "../popup";

function GenerateToken(props) {
    const classes = GenerateTokenStyle();
    return (
        <div className={classes.documentWrapper}>
            <Button
                className="orange-btn primary-btn"
                color="inherit"
                disableElevation
                underlinenone="true"
                onClick={() => props.handleClickOpen()}
            >
                Generate New Token
            </Button>
            <Popup
                open={props.open}
                handleClose={props.handleClose}
                formik={props.formik}
                data={props.data}
            />
        </div>
    );
}
export default GenerateToken;