import {
  FETCH_ONLINE_REQUEST,
  FETCH_ONLINE_REQUEST_SUCCESS,
  FETCH_ONLINE_REQUEST_FAILURE,
  CREATE_ONLINE_REQUEST,
  CREATE_ONLINE_REQUEST_SUCCESS,
  CREATE_ONLINE_REQUEST_FAILURE,
  GET_ONLINE_REQUEST_BY_ID,
  GET_ONLINE_REQUEST_BY_ID_SUCCESS,
  GET_ONLINE_REQUEST_BY_ID_FAILURE,
  EDIT_ONLINE_REQUEST,
  EDIT_ONLINE_REQUEST_SUCCESS,
  EDIT_ONLINE_REQUEST_FAILURE,
  DELETE_ONLINE_REQUEST,
  DELETE_ONLINE_REQUEST_SUCCESS,
  DELETE_ONLINE_REQUEST_FAILURE,
  GET_ONLINE_REQUEST_CHAT,
  GET_ONLINE_REQUEST_CHAT_SUCCESS,
  GET_ONLINE_REQUEST_CHAT_FAILURE,
  SAVE_ONLINE_REQUEST_CHAT,
  SAVE_ONLINE_REQUEST_CHAT_SUCCESS,
  SAVE_ONLINE_REQUEST_CHAT_FAILURE,
  FETCH_ONLINE_REQUEST_CONSIGNMENT,
  FETCH_ONLINE_REQUEST_CONSIGNMENT_SUCCESS,
  FETCH_ONLINE_REQUEST_CONSIGNMENT_FAILURE,
  ADD_ONLINE_REQUEST_CONSIGNMENT,
  ADD_ONLINE_REQUEST_CONSIGNMENT_SUCCESS,
  ADD_ONLINE_REQUEST_CONSIGNMENT_FAILURE,
  UPLOAD_IMAGE_ADD_CONSIGNMENT,
  UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS,
  UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE,
  EDIT_ONLINE_REQUEST_CONSIGNMENT,
  EDIT_ONLINE_REQUEST_CONSIGNMENT_SUCCESS,
  EDIT_ONLINE_REQUEST_CONSIGNMENT_FAILURE,
  DELETE_ONLINE_REQUEST_CONSIGNMENT,
  DELETE_ONLINE_REQUEST_CONSIGNMENT_SUCCESS,
  DELETE_ONLINE_REQUEST_CONSIGNMENT_FAILURE,
  FETCH_CONSIGNMENT_ITEM,
  FETCH_CONSIGNMENT_ITEM_SUCCESS,
  FETCH_CONSIGNMENT_ITEM_FAILURE,
  ADD_CONSIGNMENT_ITEM,
  ADD_CONSIGNMENT_ITEM_SUCCESS,
  ADD_CONSIGNMENT_ITEM_FAILURE,
  EDIT_CONSIGNMENT_ITEM,
  EDIT_CONSIGNMENT_ITEM_SUCCESS,
  EDIT_CONSIGNMENT_ITEM_FAILURE,
  DELETE_CONSIGNMENT_ITEM,
  DELETE_CONSIGNMENT_ITEM_SUCCESS,
  DELETE_CONSIGNMENT_ITEM_FAILURE,
  IMAGE_UPLOAD_CONSIGNMENT_ITEM,
  IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS,
  IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE,
  FETCH_AIR_WAY_BILLS,
  FETCH_AIR_WAY_BILLS_SUCCESS,
  FETCH_AIR_WAY_BILLS_FAILURE,
  ADD_AWB,
  ADD_AWB_SUCCESS,
  ADD_AWB_FAILURE,
  EDIT_AWB,
  EDIT_AWB_SUCCESS,
  EDIT_AWB_FAILURE,
  DELETE_AWB,
  DELETE_AWB_SUCCESS,
  DELETE_AWB_FAILURE,
  FETCH_AWB_LOOSE,
  FETCH_AWB_LOOSE_SUCCESS,
  FETCH_AWB_LOOSE_FAILURE,
  ADD_AWB_LOOSE,
  ADD_AWB_LOOSE_SUCCESS,
  ADD_AWB_LOOSE_FAILURE,
  EDIT_AWB_LOOSE,
  EDIT_AWB_LOOSE_SUCCESS,
  EDIT_AWB_LOOSE_FAILURE,
  DELETE_AWB_LOOSE,
  DELETE_AWB_LOOSE_SUCCESS,
  DELETE_AWB_LOOSE_FAILURE,
  FETCH_AWB_ULD,
  FETCH_AWB_ULD_SUCCESS,
  FETCH_AWB_ULD_FAILURE,
  ADD_AWB_ULD,
  ADD_AWB_ULD_SUCCESS,
  ADD_AWB_ULD_FAILURE,
  EDIT_AWB_ULD,
  EDIT_AWB_ULD_SUCCESS,
  EDIT_AWB_ULD_FAILURE,
  DELETE_AWB_ULD,
  DELETE_AWB_ULD_SUCCESS,
  DELETE_AWB_ULD_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  loadingOnlineRequest: false,
  onlineRequestData: null,
  creatingOnlineRequest: false,
  gettingOnlineRequestById: false,
  getOnlineRequestByIdData: null,
  updatingOnlineRequest: false,
  deletingOnlineRequest: false,
  loadingOnlineRequestChat: false,
  onlineRequestChats: null,
  savingChat: false,

  loadingOnlineRequestConsignment: false,
  onlineRequestConsignmentData: null,
  addingOnlineRequestConsignment: false,
  addOnlineRequestConsignmentData: null,
  uploadingImage: false,
  editingOnlineRequestConsignment: false,
  editOnlineRequestConsignmentData: null,
  deletingOnlineRequestConsignment: false,
  deleteOnlineRequestConsignmentData: null,

  loadingConsignmentItems: false,
  consignmentItems: null,
  addingConsignmentItem: false,
  addConsignmentItem: null,
  editingConsignmentItem: false,
  editConsignmentItem: null,
  deletingConsignmentItem: false,
  deleteConsignmentItem: null,
  uploadingItemImage: false,

  airWayBillData: null,
  loadingAirWayBill: false,
  addAirWayBill: null,
  addAirWayBillLoad: false,
  editingAWB: false,
  editAirwayBillData: null,
  deletetingAWB: false,
  deleteAWBData: null,

  awbLooseData: null,
  addingAwbLoose: false,
  addAwbLooseData: null,
  editingAwbLoose: false,
  editAwbLooseData: null,
  deletingAwbLoose: false,
  deleteAwbLooseData: null,
  loadingAwbUld: false,
  awbUldData: null,
  addingAwbUld: false,
  addAwbUldData: null,
  editingAwbUld: false,
  editAwbUldData: null,
  deletingAwbUld: false,
  deleteAwbUldData: null,
};

const OnlineRequestReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ONLINE_REQUEST:
      return { ...state, loadingOnlineRequest: true };
    case FETCH_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        onlineRequestData: action.payload,
        loadingOnlineRequest: false,
      };
    case FETCH_ONLINE_REQUEST_FAILURE:
      return {
        ...state,
        onlineRequestData: action.payload,
        loadingOnlineRequest: false,
      };

    case CREATE_ONLINE_REQUEST:
      return { ...state, creatingOnlineRequest: true };
    case CREATE_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        creatingOnlineRequest: false,
      };
    case CREATE_ONLINE_REQUEST_FAILURE:
      return {
        ...state,
        creatingOnlineRequest: false,
      };

    case GET_ONLINE_REQUEST_BY_ID:
      return {
        ...state,
        gettingOnlineRequestById: true,
      };
    case GET_ONLINE_REQUEST_BY_ID_SUCCESS:
      return {
        ...state,
        getOnlineRequestByIdData: action.payload,
        gettingOnlineRequestById: false,
      };
    case GET_ONLINE_REQUEST_BY_ID_FAILURE:
      return {
        ...state,
        getOnlineRequestByIdData: action.payload,
        gettingOnlineRequestById: false,
      };

    case EDIT_ONLINE_REQUEST:
      return { ...state, updatingOnlineRequest: true };
    case EDIT_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        updatingOnlineRequest: false,
      };
    case EDIT_ONLINE_REQUEST_FAILURE:
      return {
        ...state,
        updatingOnlineRequest: false,
      };

    case DELETE_ONLINE_REQUEST:
      return { ...state, deletingOnlineRequest: true };
    case DELETE_ONLINE_REQUEST_SUCCESS:
      return {
        ...state,
        deletingOnlineRequest: false,
      };
    case DELETE_ONLINE_REQUEST_FAILURE:
      return {
        ...state,
        deletingOnlineRequest: false,
      };

    case GET_ONLINE_REQUEST_CHAT:
      return {
        ...state,
        loadingOnlineRequestChat: true,
      };
    case GET_ONLINE_REQUEST_CHAT_SUCCESS:
      return {
        ...state,
        onlineRequestChats: action.payload,
        loadingOnlineRequestChat: false,
      };
    case GET_ONLINE_REQUEST_CHAT_FAILURE:
      return {
        ...state,
        onlineRequestChats: action.payload,
        loadingOnlineRequestChat: false,
      };

    case SAVE_ONLINE_REQUEST_CHAT:
      return {
        ...state,
        savingChat: true,
      };
    case SAVE_ONLINE_REQUEST_CHAT_SUCCESS:
      return {
        ...state,
        savingChat: false,
      };
    case SAVE_ONLINE_REQUEST_CHAT_FAILURE:
      return {
        ...state,
        savingChat: false,
      };

    case FETCH_ONLINE_REQUEST_CONSIGNMENT:
      return { ...state, loadingOnlineRequestConsignment: true };
    case FETCH_ONLINE_REQUEST_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        onlineRequestConsignmentData: action.payload,
        loadingOnlineRequestConsignment: false,
      };
    case FETCH_ONLINE_REQUEST_CONSIGNMENT_FAILURE:
      return {
        ...state,
        onlineRequestConsignmentData: action.payload,
        loadingOnlineRequestConsignment: false,
      };

    case ADD_ONLINE_REQUEST_CONSIGNMENT:
      return { ...state, addingOnlineRequestConsignment: true };
    case ADD_ONLINE_REQUEST_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        addOnlineRequestConsignmentData: action.payload,
        addingOnlineRequestConsignment: false,
      };
    case ADD_ONLINE_REQUEST_CONSIGNMENT_FAILURE:
      return {
        ...state,
        addOnlineRequestConsignmentData: action.payload,
        addingOnlineRequestConsignment: false,
      };

    case EDIT_ONLINE_REQUEST_CONSIGNMENT:
      return { ...state, editingOnlineRequestConsignment: true };
    case EDIT_ONLINE_REQUEST_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        editOnlineRequestConsignmentData: action.payload,
        editingOnlineRequestConsignment: false,
      };
    case EDIT_ONLINE_REQUEST_CONSIGNMENT_FAILURE:
      return {
        ...state,
        editOnlineRequestConsignmentData: action.payload,
        editingOnlineRequestConsignment: false,
      };

    case DELETE_ONLINE_REQUEST_CONSIGNMENT:
      return { ...state, deletingOnlineRequestConsignment: true };
    case DELETE_ONLINE_REQUEST_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        deleteOnlineRequestConsignmentData: action.payload,
        deletingOnlineRequestConsignment: false,
      };
    case DELETE_ONLINE_REQUEST_CONSIGNMENT_FAILURE:
      return {
        ...state,
        deleteOnlineRequestConsignmentData: action.payload,
        deletingOnlineRequestConsignment: false,
      };

    case UPLOAD_IMAGE_ADD_CONSIGNMENT:
      return { ...state, uploadingImage: true };
    case UPLOAD_IMAGE_ADD_CONSIGNMENT_SUCCESS:
      return {
        ...state,
        uploadingImage: false,
      };
    case UPLOAD_IMAGE_ADD_CONSIGNMENT_FAILURE:
      return {
        ...state,
        uploadingImage: false,
      };

    case FETCH_CONSIGNMENT_ITEM:
      return { ...state, loadingConsignmentItems: true };
    case FETCH_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        consignmentItems: action.payload,
        loadingConsignmentItems: false,
      };
    case FETCH_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        consignmentItems: action.payload,
        loadingConsignmentItems: false,
      };

    case ADD_CONSIGNMENT_ITEM:
      return { ...state, addingConsignmentItem: true };
    case ADD_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        addConsignmentItem: action.payload,
        addingConsignmentItem: false,
      };
    case ADD_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        addConsignmentItem: action.payload,
        addingConsignmentItem: false,
      };

    case EDIT_CONSIGNMENT_ITEM:
      return { ...state, editingConsignmentItem: true };
    case EDIT_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        editConsignmentItem: action.payload,
        editingConsignmentItem: false,
      };
    case EDIT_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        editConsignmentItem: action.payload,
        editingConsignmentItem: false,
      };

    case DELETE_CONSIGNMENT_ITEM:
      return { ...state, deletingConsignmentItem: true };
    case DELETE_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        deleteConsignmentItem: action.payload,
        deletingConsignmentItem: false,
      };
    case DELETE_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        deleteConsignmentItem: action.payload,
        deletingConsignmentItem: false,
      };

    case IMAGE_UPLOAD_CONSIGNMENT_ITEM:
      return { ...state, uploadingItemImage: true };
    case IMAGE_UPLOAD_CONSIGNMENT_ITEM_SUCCESS:
      return {
        ...state,
        uploadingItemImage: false,
      };
    case IMAGE_UPLOAD_CONSIGNMENT_ITEM_FAILURE:
      return {
        ...state,
        uploadingItemImage: false,
      };

    case FETCH_AIR_WAY_BILLS:
      return { ...state, loadingAirWayBill: true };
    case FETCH_AIR_WAY_BILLS_SUCCESS:
      return {
        ...state,
        airWayBillData: action.payload,
        loadingAirWayBill: false,
      };
    case FETCH_AIR_WAY_BILLS_FAILURE:
      return {
        ...state,
        airWayBillData: action.payload,
        loadingAirWayBill: false,
      };

    case ADD_AWB:
      return { ...state, addAirWayBillLoad: true };
    case ADD_AWB_SUCCESS:
      return {
        ...state,
        addAirWayBill: action.payload,
        addAirWayBillLoad: false,
      };
    case ADD_AWB_FAILURE:
      return {
        ...state,
        addAirWayBill: action.payload,
        addAirWayBillLoad: false,
      };

    case EDIT_AWB:
      return { ...state, editingAWB: true };
    case EDIT_AWB_SUCCESS:
      return {
        ...state,
        editAirwayBillData: action.payload,
        editingAWB: false,
      };
    case EDIT_AWB_FAILURE:
      return {
        ...state,
        editAirwayBillData: action.payload,
        editingAWB: false,
      };

    case DELETE_AWB:
      return { ...state, deletetingAWB: true };
    case DELETE_AWB_SUCCESS:
      return {
        ...state,
        deleteAWBData: action.payload,
        deletetingAWB: false,
      };
    case DELETE_AWB_FAILURE:
      return {
        ...state,
        deleteAWBData: action.payload,
        deletetingAWB: false,
      };

    case FETCH_AWB_LOOSE:
      return { ...state, loadingAwbLoose: true };
    case FETCH_AWB_LOOSE_SUCCESS:
      return {
        ...state,
        awbLooseData: action.payload,
        loadingAwbLoose: false,
      };
    case FETCH_AWB_LOOSE_FAILURE:
      return {
        ...state,
        awbLooseData: action.payload,
        loadingAwbLoose: false,
      };

    case ADD_AWB_LOOSE:
      return { ...state, addingAwbLoose: true };
    case ADD_AWB_LOOSE_SUCCESS:
      return {
        ...state,
        addAwbLooseData: action.payload,
        addingAwbLoose: false,
      };
    case ADD_AWB_LOOSE_FAILURE:
      return {
        ...state,
        addAwbLooseData: action.payload,
        addingAwbLoose: false,
      };

    case EDIT_AWB_LOOSE:
      return { ...state, editingAwbLoose: true };
    case EDIT_AWB_LOOSE_SUCCESS:
      return {
        ...state,
        editAwbLooseData: action.payload,
        editingAwbLoose: false,
      };
    case EDIT_AWB_LOOSE_FAILURE:
      return {
        ...state,
        editAwbLooseData: action.payload,
        editingAwbLoose: false,
      };

    case DELETE_AWB_LOOSE:
      return { ...state, deletingAwbLoose: true };
    case DELETE_AWB_LOOSE_SUCCESS:
      return {
        ...state,
        deleteAwbLooseData: action.payload,
        deletingAwbLoose: false,
      };
    case DELETE_AWB_LOOSE_FAILURE:
      return {
        ...state,
        deleteAwbLooseData: action.payload,
        deletingAwbLoose: false,
      };

    case FETCH_AWB_ULD:
      return { ...state, loadingAwbUld: true };
    case FETCH_AWB_ULD_SUCCESS:
      return {
        ...state,
        awbUldData: action.payload,
        loadingAwbUld: false,
      };
    case FETCH_AWB_ULD_FAILURE:
      return {
        ...state,
        awbUldData: action.payload,
        loadingAwbUld: false,
      };

    case ADD_AWB_ULD:
      return { ...state, addingAwbUld: true };
    case ADD_AWB_ULD_SUCCESS:
      return {
        ...state,
        addAwbUldData: action.payload,
        addingAwbUld: false,
      };
    case ADD_AWB_ULD_FAILURE:
      return {
        ...state,
        addAwbUldData: action.payload,
        addingAwbUld: false,
      };

    case EDIT_AWB_ULD:
      return { ...state, editingAwbUld: true };
    case EDIT_AWB_ULD_SUCCESS:
      return {
        ...state,
        editAwbUldData: action.payload,
        editingAwbUld: false,
      };
    case EDIT_AWB_ULD_FAILURE:
      return {
        ...state,
        editAwbUldData: action.payload,
        editingAwbUld: false,
      };

    case DELETE_AWB_ULD:
      return { ...state, deletingAwbUld: true };
    case DELETE_AWB_ULD_SUCCESS:
      return {
        ...state,
        deleteAwbUldData: action.payload,
        deletingAwbUld: false,
      };
    case DELETE_AWB_ULD_FAILURE:
      return {
        ...state,
        deleteAwbUldData: action.payload,
        deletingAwbUld: false,
      };

    default:
      return state;
  }
};
export default OnlineRequestReducer;
