import { makeStyles } from "@material-ui/core/styles";

const myProfileStyle = makeStyles((theme) => ({
  profileWrapper: {
    "& .MuiBackdrop-root": {
      color: " #fff",
      zIndex: "1201",
    },
    "& .white-card": {
      "& .custom-form": {
        "& .form-row": {
          "& .error-text": {
            color: "red",
          },
          "& .form-gourp": {
            marginBottom: "27px",
          },
        },
        "& .upload-img-wrapper": {
          position: "relative",
          display: "inline-block",
          marginBottom: "29px",
          "& .img-block": {
            "& img": {
              margin: "0 0 15px",
              height: "120px",
              width: "auto",
              borderRadius: "50%",
              // objectFit: "cover",
            },
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "130px",
            width: "auto",
            borderRadius: "50%",
          },
          "& .form-gourp": {
            position: "absolute",
            bottom: "0px",
            right: "13px",
            margin: "0 !important",
            cursor: "pointer",
            "& input[type='file']": {
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              height: "100%",
              width: "100%",
              opacity: "0",
              zIndex: "4",
              lineHeight: "1",
              cursor: "pointer",
            },
            "&  ::-webkit-file-upload-button": {
              cursor: "pointer",
            },
          },
        },
      },
    },
  },
}));

export { myProfileStyle };
