import React, { useState, useEffect } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { toast } from "react-toastify";
import { Button } from "@material-ui/core";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import { schema } from "@utils/schemas";
import validationSchema from "@utils/validationSchemas";
import {
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAILURE,
  GET_JOBTYPES,
  GET_JOBTYPES_SUCCESS,
  GET_JOBTYPES_FAILURE,
  GET_CARGO_TYPE,
  GET_CARGO_TYPE_SUCCESS,
  GET_CARGO_TYPE_FAILURE,
  GET_CTOS,
  GET_CTOS_SUCCESS,
  GET_CTOS_FAILURE,
  GET_ADDRESS,
  GET_ADDRESS_SUCCESS,
  GET_ADDRESS_FAILURE,
  CREATE_ONLINE_REQUEST,
  CREATE_ONLINE_REQUEST_SUCCESS,
  CREATE_ONLINE_REQUEST_FAILURE,
} from "@utils/actionTypes";
import { getUserData } from "@utils/commonFunctions";
import { getApi, postApi } from "@services/axios";
import Popup from "./popup";
import { requestFormStyle } from "./style";

function RequestForm(props) {
  const classes = requestFormStyle();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [state, dispatch] = useStore();

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
    setError("");
  };

  // API calling to add job
  const formik = useFormik({
    initialValues: schema.makeRequestSchema,
    validationSchema: validationSchema.makeRequestValidationSchema,
    onSubmit: (value) => {
      let data = {
        referenceNo: value.referenceNo,
        jobTypeId: value.jobTypeId,
        pickUpLocation: value.pickUpLocation
          ? value.pickUpLocation
          : (value.ctoId &&
              value.jobTypeLabel === "Empty" &&
              value.radioType === "pickUp") ||
            value.jobTypeLabel === "Import"
          ? value.ctoData?.location
            ? value.ctoData?.location
            : props.jobsData?.pickUpLocation
          : "",
        pickUpLatitude: value.pickUpLatitude
          ? value.pickUpLatitude
          : value.ctoId
          ? value.ctoData?.latitude
            ? value.ctoData?.latitude
            : props.jobsData?.pickUpLatitude
          : "",
        pickUpLongitude: value.pickUpLongitude
          ? value.pickUpLongitude
          : value.ctoId
          ? value.ctoData?.longitude
            ? value.ctoData?.longitude
            : props.jobsData?.pickUpLongitude
          : "",
        dropOffLocation: value.dropOffLocation
          ? value.dropOffLocation
          : (value.ctoId &&
              value.jobTypeLabel === "Empty" &&
              value.radioType === "dropOff") ||
            value.jobTypeLabel === "Export"
          ? value.ctoData?.location
            ? value.ctoData?.location
            : props.jobsData?.dropOffLocation
          : "",
        dropOffLatitude: value.dropOffLatitude
          ? value.dropOffLatitude
          : value.ctoId
          ? value.ctoData?.latitude
            ? value.ctoData?.latitude
            : props.jobsData?.dropOffLatitude
          : "",
        dropOffLongitude: value.dropOffLongitude
          ? value.dropOffLongitude
          : value.ctoId
          ? value.ctoData?.longitude
            ? value.ctoData?.longitude
            : props.jobsData?.dropOffLongitude
          : "",
      };
      data = {
        ...data,
        ...(!!value.requesterName
          ? { requesterName: value.requesterName }
          : {}),
        ...(!!value.email ? { email: value.email } : {}),
        ...(!!value.cc.length ? { cc: value.cc?.toString() } : {}),
        ...(!!value.cargoTypeId ? { cargoTypeId: value.cargoTypeId } : {}),
        ...(!!value.quantity
          ? { quantity: value.jobTypeLabel === "Empty" && value.quantity }
          : {}),
        ...(!!value.radioType
          ? { ctoType: value.radioType === "pickUp" ? "1" : "2" }
          : {}),
        ...(!!value.ctoId ? { ctoId: value.ctoId } : {}),
        ...(!!value.pickUpCustomerAddressId
          ? { pickUpCustomerAddressId: value.pickUpCustomerAddressId }
          : {}),
        ...(!!value.dropOffCustomerAddressId
          ? { dropOffCustomerAddressId: value.dropOffCustomerAddressId }
          : {}),
        ...(!!value.description ? { description: value.description } : {}),
      };
      if (!data.pickUpLocation) data.pickUpLocation = value.ctoData?.location;
      if (!data.pickUpLatitude) data.pickUpLatitude = value.ctoData?.latitude;
      if (!data.pickUpLongitude)
        data.pickUpLongitude = value.ctoData?.longitude;
      if (!data.dropOffLocation) data.dropOffLocation = value.ctoData?.location;
      if (!data.dropOffLatitude) data.dropOffLatitude = value.ctoData?.latitude;
      if (!data.dropOffLongitude)
        data.dropOffLongitude = value.ctoData?.longitude;
      dispatch({ type: CREATE_ONLINE_REQUEST });
      postApi("onlineRequests", data)
        .then((response) => {
          dispatch({
            type: CREATE_ONLINE_REQUEST_SUCCESS,
            payload: response.data.data,
          });
          props.getOnlineRequest();
          handleClose();
          toast.success("Online Request Created SuccessFully");
        })
        .catch((error) => {
          dispatch({ type: CREATE_ONLINE_REQUEST_FAILURE, payload: error });
          setError(error.response?.data?.message);
        });
    },
  });

  const handleChip = (e) => {
    formik.setFieldValue("cc", e);
  };

  // API calling when create job popup opens
  useEffect(() => {
    if (open) {
      dispatch({ type: GET_ADDRESS });
      getApi("master/addresses", null, false)
        .then((response) => {
          dispatch({
            type: GET_ADDRESS_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_ADDRESS_FAILURE, payload: error });
        });

      dispatch({ type: GET_CITIES });
      getApi("master/cities", null, false)
        .then((response) => {
          dispatch({
            type: GET_CITIES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CITIES_FAILURE, payload: error });
        });

      dispatch({ type: GET_JOBTYPES });
      getApi("master/jobTypes", null, false)
        .then((response) => {
          dispatch({
            type: GET_JOBTYPES_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_JOBTYPES_FAILURE, payload: error });
        });

      dispatch({ type: GET_CARGO_TYPE });
      getApi("master/cargoTypes", null, false)
        .then((response) => {
          dispatch({
            type: GET_CARGO_TYPE_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CARGO_TYPE_FAILURE, payload: error });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  // API calling to get list of cities
  useEffect(() => {
    if (state?.common?.ctosData === null) {
      const params = {
        cityId: getUserData()?.cityId,
      };
      dispatch({ type: GET_CTOS });
      getApi("master/ctos", { params }, false)
        .then((response) => {
          dispatch({
            type: GET_CTOS_SUCCESS,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          dispatch({ type: GET_CTOS_FAILURE, payload: error });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Render cargo type on basis of jobType
  useEffect(() => {
    formik.setFieldValue("ctoId", "");
    formik.setFieldValue("radioType", "");
    formik.setFieldValue("quantity", 0);
    if (
      ["Metro", "Airside", "Ad-Hoc", "Transfer", "Interstate"].includes(
        formik.values.jobTypeLabel
      )
    ) {
      formik.setFieldValue("cargoTypeId", "");
    }
    if (formik.values.jobTypeLabel === "Empty") {
      formik.setFieldValue(
        "cargoTypeId",
        state?.common?.cargoTypeData?.find((item) => item.name === "ULD").id
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.jobTypeLabel]);

  // Clearing  location fields when jobType updates
  useEffect(() => {
    formik.setFieldValue("dropOffLocation", "");
    formik.setFieldValue("pickUpLocation", "");
    formik.setFieldValue("pickUpCustomerAddressId", "");
    formik.setFieldValue("dropOffCustomerAddressId", "");
    // eslint-disable-next-line
  }, [formik.values.jobTypeId, formik.values.radioType]);

  const handleChange = (address, type) => {
    type === "pickUpLocation"
      ? formik.setFieldValue("pickUpLocation", address)
      : formik.setFieldValue("dropOffLocation", address);
  };

  // Handle lat/long and address of google place api
  const handleSelect = (address, type) => {
    type === "pickUpLocation"
      ? formik.setFieldValue("pickUpLocation", address)
      : formik.setFieldValue("dropOffLocation", address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        return type === "pickUpLocation"
          ? (formik.setFieldValue("pickUpLatitude", latLng.lat),
            formik.setFieldValue("pickUpLongitude", latLng.lng))
          : (formik.setFieldValue("dropOffLatitude", latLng.lat),
            formik.setFieldValue("dropOffLongitude", latLng.lng));
      })
      .catch((error) => console.error("Error", error));
  };

  return (
    <div className={classes.RequestFormWrapper}>
      <Button
        className="orange-btn lg"
        color="inherit"
        disableElevation
        onClick={() => setOpen(true)}
      >
        + NEW REQUEST
      </Button>

      <Popup
        open={open}
        handleClose={handleClose}
        formik={formik}
        handleChange={handleChange}
        handleSelect={handleSelect}
        error={error}
        handleChip={handleChip}
      />
    </div>
  );
}

export default RequestForm;
