import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  SwipeableDrawer,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useFormik } from "formik";

import { useStore } from "@store/store";
import closeIcon from "@assets/images/close.svg";
import { schema } from "@utils/schemas";
import {
  FETCH_JOB_AWB_LOOSE,
  FETCH_JOB_AWB_LOOSE_SUCCESS,
  FETCH_JOB_AWB_LOOSE_FAILURE,
} from "@utils/actionTypes";
import { getApi } from "@services/axios";
import AddLoose from "./add-loose";
import { LooseStyle } from "./style";

function Loose(props) {
  const [open, setOpen] = useState(false);
  const [getState, setState] = useState({ right: false });
  const [getIndex, setIndex] = useState("");
  const [initialValue, setInitialValue] = useState(schema.viewJobLooseSchema);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, dispatch] = useStore();
  const { id } = useParams();
  const classes = LooseStyle();

  let getAwbLoose = () => {
    dispatch({ type: FETCH_JOB_AWB_LOOSE });
    getApi(`jobs/${id}/airWaybills/${props.getIndex}/looses`, null, false)
      .then((response) => {
        dispatch({
          type: FETCH_JOB_AWB_LOOSE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({ type: FETCH_JOB_AWB_LOOSE_FAILURE, payload: error });
      });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValue,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...getState, [anchor]: open });
    if (open) getAwbLoose();
  };

  const handleClick = (event, index) => {
    setIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSidebar = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    formik.handleReset();
  };

  const handleEditOpen = (item) => {
    setAnchorEl(null);
    setOpen(true);
    setInitialValue({
      number: item.number,
      quantity: item.quantity,
    });
  };

  return (
    <div className={classes.ULDsWrapper}>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button
            className="primary-btn blue-btn lg"
            variant="contained"
            color="primary"
            disableElevation
            onClick={toggleDrawer(anchor, true)}
          >
            Loose
          </Button>
          <SwipeableDrawer
            className={classes.drawerWrapper}
            anchor={anchor}
            open={getState[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className="drawer-wrapper">
              <div className="filter-title-block form-group">
                <Typography variant="h2">Loose</Typography>
                <img
                  src={closeIcon}
                  alt="Close"
                  onClick={toggleDrawer(anchor, false)}
                />
              </div>
              <AddLoose
                formik={formik}
                open={open}
                handleClickOpen={handleClickOpen}
                handleClose={handleClose}
              />
              <div className="uld-list-wrapper">
                {state.job.loadingAwbLoose ? (
                  <div className="circular-progress">
                    <CircularProgress color="inherit" size={60} />
                  </div>
                ) : state.job.awbLooseData?.count === 0 ? (
                  <div className="uld-list-item">
                    <span className="uld-id">No Items Found</span>
                  </div>
                ) : (
                  state.job.awbLooseData?.rows?.map((item, index) => {
                    return (
                      <div className="uld-list-item" key={index}>
                        <span className="uld-id">{item.quantity}</span>
                        <div className={classes.moreLinkWrapper}>
                          <div className="more-link-block">
                            <span
                              className="more-link"
                              aria-controls="menu"
                              aria-haspopup="true"
                              onClick={(e) => handleClick(e, index)}
                            >
                              <MoreVertIcon />
                            </span>
                            <Menu
                              id="simple-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={getIndex === index && Boolean(anchorEl)}
                              onClose={handleCloseSidebar}
                              className={classes.customMenu}
                              menuprops={{
                                classes: { paper: classes.customMenu },
                              }}
                            >
                              <MenuItem onClick={() => handleEditOpen(item)}>
                                View
                              </MenuItem>
                            </Menu>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}

export default Loose;
