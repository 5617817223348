import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";

import { TabbingStyle } from "./style";
import AirWayBill from "./air-waybills";
import Consignments from "./consignments";

function Tabbing(props) {
	const classes = TabbingStyle();
	const [value, setValue] = useState(0);
	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};
	function TabPanel(props) {
		const { children, value, index } = props;
		return (
			value === index && <div className="tab-pannel-wrapper"> {children} </div>
		);
	}

	return (
		<div className={classes.TabbingWrapper}>
			<div className="white-card tabbing-wrapper">
				<Tabs
					value={value}
					onChange={handleChangeTab}
					textColor="primary"
					variant="scrollable"
					scrollButtons="auto"
				>
					{["Import", "Export", "Empty", "Temp Control"].includes(
						props.onlineData?.jobTypes?.name
					) ? (
						<Tab label={`Air waybills`} />
					) : (
						<Tab label={`Consignments`} />
					)}
				</Tabs>

				<TabPanel value={value} index={0}>
					{["Import", "Export", "Empty", "Temp Control"].includes(
						props.onlineData?.jobTypes?.name
					) ? (
						<AirWayBill onlineData={props.onlineData} />
					) : (
						<Consignments onlineData={props.onlineData} />
					)}
				</TabPanel>
			</div>
		</div>
	);
}
export default React.memo(Tabbing);
