import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

import { useStore } from "@store/store";
import { utcToLocal } from "@utils/commonFunctions";
import { routes } from "@utils/constant";
import { SideBarStyle } from "./style";

function JobsListOnMap(props) {
	const classes = SideBarStyle();
	const [state] = useStore();
	let data = [];

	state.mapCockpit?.mapcockpitData?.filter((item) => {
		if (item?.jobStatuses?.name === "In Transit") {
			data.unshift(item);
		} else {
			data.push(item);
		}
		return true;
	});

	const setColorClass = (item) => {
		const dt1 = moment.tz("UTC");
		const dt2 = moment(item?.jobRunsheets[0] && item?.jobRunsheets[0].time);
		const diffTime = Math.abs(dt1 - dt2);
		let hours = Math.floor(diffTime / (1000 * 60 * 60));
		if ([2, 8].includes(item?.jobRunsheets[0]?.jobRunsheetStatuses?.id)) {
			if (hours >= 2) {
				return "uld-list-item-red";
			} else {
				return "uld-list-item";
			}
		} else {
			return "uld-list-item";
		}
	};

	return (
		<React.Fragment>
			<div className={classes.drawerWrapper}>
				<div className="drawer-wrapper">
					<div className="uld-list-wrapper">
						{!!state.mapCockpit?.mapcockpitData > 0 &&
							data?.map((item, index) => {
								return (
									<div className={setColorClass(item)} key={index}>
										<p>Job Type: {item.jobTypes?.name || "-"}</p>
										<span>Job Number: </span>
										<Link
											to={`${routes.jobDetail}/${item.id}`}
											target="_blank"
											className="uld-id"
										>
											{item?.id}
										</Link>
										<p>Job Status: {item.jobStatuses?.name || "-"}</p>
										<p>Truck Rego: {item.trucks?.rego || "-"}</p>
										<p>CTO: {item.ctos?.name || "-"}</p>
										<p>Cargo Type: {item.cargoTypes?.name || "-"}</p>
										<p>
											Job Started:{" "}
											{item?.startedAt
												? utcToLocal(
														item?.startedAt,
														item?.cities?.timezone,
														"DD/MM/yyyy hh:mm a"
												  )
												: "-"}
										</p>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

export default JobsListOnMap;
