const basic = {
	basic: "",
};

const subItemsSchema = {
	awbUldPieces: 0,
	awbUldWeight: 0,
	awbUldVolume: 0,
	awbUldNumber: "",
	awbLooseWeight: 0,
	awbLoosePieces: 0,
	awbLooseNumber: "",
	consignmentItemNumber: 0,
	consignmentPieces: 0,
	consignmentWeight: 0,
	consignmentInfo: "",
};

const tabSchema = {
	airWayBill: "",
	airLine: "",
	readyDate: null,
	readyTime: null,
	cutOffTime: null,
	subItems: [subItemsSchema],
	totalPieces: 0,
	totalWeight: 0,
	consignmentNumber: "",
	consignmentPhoto: null,
	consignmentTotalWeight: 0,
	consignmentTotalPieces: 0,
	dangerousGood: false,
	dgClassification: "",
	unNumber: 0,
	perVehicle: 0,
	packaging: 0,
	paperWork: "",
};

const schema = {
	loginSchema: {
		email: "",
		password: "",
	},

	changePasswordSchema: {
		currentPassword: "",
		newPassword: "",
		confirmPassword: "",
	},

	myProfileSchema: {
		name: "",
		email: "",
		profileImage: "",
		isCargoWise: false
	},

	makeRequestSchema: {
		referenceNo: "",
		jobTypeId: "",
		jobTypeLabel: "",
		requesterName: "",
		email: "",
		cc: [],
		cargoTypeId: "",
		ctoId: "",
		quantity: "",
		pickUpLocation: "",
		pickUpLatitude: "",
		pickUpLongitude: "",
		pickUpCustomerAddressId: "",
		dropOffLocation: "",
		dropOffLatitude: "",
		dropOffLongitude: "",
		dropOffCustomerAddressId: "",
		description: "",
		radioType: "",
	},
	addAirWayBillSchema: {
		number: "",
		flight: "",
		weight: "",
		quantity: "",
		readyDate: null,
		readyTime: null,
		cutOffTime: null,
		airWaybillStatusId: "",
		id: "",
		dangerousGood: false,
		dgClassification: "",
		unNumber: "",
		volume: "",
		indVolume: "",
		rePaperWork: "",
	},

	addConsignMentSchema: {
		number: "",
		photo: "",
		weight: "",
		quantity: "",
		id: "",
		viewPhoto: "",
		dangerousGood: false,
		dgClassification: "",
		unNumber: "",
		volume: "",
		indVolume: "",
		rePaperWork: "",
	},

	addConsignMentItemSchema: {
		additionalInfo: "",
		quantity: "",
		photo: "",
		id: "",
		viewPhoto: "",
	},

	addJobConsignMentItemSchema: {
		additionalInfo: "",
		quantity: "",
		photo: "",
	},

	viewJobUlDSchema: {
		volume: "",
		quantity: 1,
		uldNumber: "",
		overhang: false,
	},

	addULDSchema: {
		volume: "",
		quantity: 1,
		uldNumber: "",
		overhang: false,
		id: "",
		number: "",
	},

	addLooseSchema: {
		quantity: "",
		id: "",
	},

	viewJobLooseSchema: {
		quantity: "",
	},

	selectParentSchema: {
		parentId: "",
	},

	generateTokenSchema: {
		tokenName: "",
		job: 1,
		truck: 1,
	},
};

export { schema, basic, subItemsSchema, tabSchema };
