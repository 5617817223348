import { makeStyles } from "@material-ui/core/styles";

import { colors } from "@utils/constant";

const requestFormStyle = makeStyles((theme) => ({
  RequestFormWrapper: {},
  customModal: {
    "& .MuiDialog-paperWidthSm": {
      boxShadow: "0 5px 8px 0 rgba(0, 0, 0, 0.24)",
      padding: "36px 40px 40px",
      maxWidth: "820px",
      width: "100%",
      "@media (max-width: 991px)": {
        maxWidth: "100%",
        padding: "20px 15px",
        margin: "30px 15px",
        width: "auto",
      },
      "& .MuiDialogTitle-root": {
        padding: "0",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
        "@media (max-width: 480px)": {
          marginBottom: "20px",
        },
      },
      "& .MuiDialogContent-root": {
        padding: "0",
      },
      "& h2": {
        fontSize: "24px",
        fontWeight: "700",
        lineHeight: "40px",
      },
    },
    "& .close-modal": {
      position: "absolute",
      right: "40px",
      top: "45px",
      cursor: "pointer",
      "@media (max-width: 991px)": {
        top: "28px",
        right: "25px",
      },
    },
    "& .MuiFormControl-root": {
      "& > div": {
        "&::after": {
          display: "none",
        },
      },
    },
    "& .MuiInputBase-root.MuiInput-root": {
      border: "none",
      "& input": {
        border: "none",
      },
    },
    "& .MuiFormControl-marginNormal": {
      margin: "0",
    },
    "& form": {
      overflowX: "hidden",
      overflowY: "hidden",
      "& .form-row": {
        display: "flex",
        margin: "0 -10px",
        "@media (max-width: 575px)": {
          flexWrap: "wrap",
        },
        "& .form-gourp": {
          padding: "0 10px",
        },
        "& .full-width-column": {
          maxWidth: "100%",
          flex: "0 0 100%",
        },
        "& .two-column": {
          maxWidth: "50%",
          flex: "0 0 50%",
          "@media (max-width: 575px)": {
            maxWidth: "100%",
            flex: "0 0 100%",
          },
        },
        "& .three-column": {
          maxWidth: "33.33%",
          flex: "0 0 33.33%",
          "@media (max-width: 575px)": {
            maxWidth: "100%",
            flex: "0 0 100%",
          },
        },
        "& .left-column": {
          maxWidth: "33.33%",
          flex: "0 0 33.33%",
          "@media (max-width: 575px)": {
            maxWidth: "100%",
            flex: "0 0 100%",
          },
        },
        "& .right-column": {
          maxWidth: "66.66%",
          flex: "0 0 66.66%",
          "@media (max-width: 575px)": {
            maxWidth: "100%",
            flex: "0 0 100%",
            margin: "0",
          },
          "& .three-column": {
            margin: "0",
            "@media (max-width: 575px)": {
              margin: "0 0 20px",
            },
          },
        },
        "& .label-text": {
          fontSize: "14px",
          textTransform: "capitalize",
        },

        "& .error-text": {
          color: "red",
        },
        "& .info-icon": {
          position: "relative",
          top: "-3px",
          paddingLeft: "8px",
          cursor: "pointer",
          display: "inline-block",
        },
      },
      "& .orange-text": {
        color: `${colors.orange} !important`,
        fontSize: "16px",
        cursor: "Pointer",
        marginBottom: "15px",
        display: "block",
        "&::hover": {
          textDecoration: "none",
        },
      },
    },
    "& .bottom-button-block": {
      padding: "15px 0 0",
      display: "flex",
      justifyContent: "space-between",
      "@media (max-width: 480px)": {
        flexDirection: "column",
      },
      "& .btn": {
        height: "50px",
        minWidth: "165px",
        "@media (max-width: 480px)": {
          minWidth: "100%",
          marginBottom: "15px",
        },
      },
    },
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .chip-input": {
      "& > div": {
        minHeight: "50px",
        border: "1px solid #e7e4f1",
        marginBottom: 0,
        borderRadius: "6px",
        alignItems: "center",
        padding: "10px 15px 0",
        overflow: "hidden",
        "&::before": {
          display: "none",
        },
        "&::focus": {
          border: "none",
        },
        "& input": {
          border: "none",
          padding: "0 !important",
        },
      },
      "& .MuiChip-root": {
        height: "28px",
        lineHeight: "28px",
        backgroundColor: colors.lightGraybg,
        fontSize: "16px",
        color: colors.black,
        borderRadius: "30px",
      },
      "& .MuiInput-root": {
        marginTop: "-10px",
        "& input": {
          height: "38px",
          lineHeight: "38px",
        },
      },
    },
    "& .tooltip-text": {
      fontSize: "13px",
      position: "absolute",
      padding: "0 30px",
      top: "0",
    },
    "& .airway-bill-listing": {
      padding: "15px 0 0",
    },
    "& .add-more-deetail-block": {
      padding: "15px 0 0",
    },
  },
}));

export { requestFormStyle };
