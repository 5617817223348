import {
  FETCH_INVOICES,
  FETCH_INVOICES_SUCCESS,
  FETCH_INVOICES_FAILURE,
} from "@utils/actionTypes";

const INIT_STATE = {
  laodingInvoices: false,
  invoicesData: null,
};

const invoicesReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return { ...state, laodingInvoices: true };
    case FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoicesData: action.payload,
        laodingInvoices: false,
      };
    case FETCH_INVOICES_FAILURE:
      return {
        ...state,
        invoicesData: action.payload,
        laodingInvoices: false,
      };

    default:
      return state;
  }
};
export default invoicesReducer;
