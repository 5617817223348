import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper, Polyline } from "google-maps-react";

import { useStore } from "@store/store";
import OrangeImg from "@assets/images/orange_circle.svg";
import BlueImg from "@assets/images/blue_circle.svg";
import { jobRunSheetStatus } from "@utils/constant";

function MapDisplay(props) {
  const [state] = useStore();

  const [polyData, setPolyData] = useState([]);
  const [getBound, setBounds] = useState(null);
  const [centerPoint, setCenterPoint] = useState();

  const getPolyData = () => {
    let temp = [];
    const jobStreetByStreet = state.job?.jobStreetByStreet;
    const hasJobStreetByStreet = Array.isArray(jobStreetByStreet) && jobStreetByStreet?.length > 0;

    if(props.jobsData?.isAuto && hasJobStreetByStreet) {
    state.job?.jobStreetByStreet?.map((item, i) => {
      temp.push({
        lat: parseFloat(item.latitude),
        lng: parseFloat(item.longitude),
        status: item.status,
      });
      return true;
    })} else {
      state.job?.jobRunSheetData?.jobRunsheetReport?.map((item) => {
        temp.push({
          lat: parseFloat(item.latitude),
          lng: parseFloat(item.longitude),
          status: item.jobRunsheetStatuses?.code,
        });
        return true;
    })}
    setPolyData(temp);
    const [firstItem] = temp;
    setCenterPoint(firstItem);
  }
  
  const handleOnReady = () => {
    const bounds = new props.google.maps.LatLngBounds();
    for (let loc of polyData) bounds.extend({ lat: loc.lat, lng: loc.lng });
    setBounds(bounds);
  };

  useEffect(() => {
    !state.job?.gettingStreetByStreet && getPolyData();
  }, [props.jobsData?.isAuto, state.job?.jobStreetByStreet, state.job?.jobRunSheetData]);

  return (
    <div className="map-container">
      <Map
        google={props.google}
        zoom={3}
        mapTypeControl={false}
        bounds={getBound}
        onReady={handleOnReady}
        center={centerPoint}
      >
        {polyData.map((cords, index) => (
          <Marker
            key={index}
            name="Marker"
            position={{ lat: cords.lat, lng: cords.lng }}
            icon={{
              url: [jobRunSheetStatus.START_BREAK, jobRunSheetStatus.FINISH_BREAK].includes(cords.status)
                ? OrangeImg
                : BlueImg,
              anchor: new props.google.maps.Point(7, 7),
              scaledSize: new props.google.maps.Size(12, 12),
            }}
          />
        ))}
        <Polyline
          path={polyData}
          options={{
            strokeColor: "#00548E",
            strokeOpacity: 1,
            strokeWeight: 4,
          }}
        />
      </Map>
    </div>
  );
}

const LoadingContainer = () => "";

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_PLACE_API,
  LoadingContainer: LoadingContainer,
})(MapDisplay);
